import { createContext, memo, useContext, useMemo, useState } from 'react'
import { Board, CardInDeckListFragment } from 'types/graphql'
import * as helpers from '../helpers'
import { DeckViewContext, GroupType, GroupingType } from './types'

export const viewContext = createContext<DeckViewContext>({
  groupBy: GroupingType.TYPE,
  setGroupBy: () => undefined,
  groups: [],
})

export const useDeckView = () => {
  return useContext(viewContext)
}

const isCardListEqual = (prev: ProviderProps, next: ProviderProps) => {
  const prevCardsString = prev.cards
    .map((c) => c.card.id)
    .sort()
    .join(',')
  const nextCardsString = next.cards
    .map((c) => c.card.id)
    .sort()
    .join(',')

  return prevCardsString === nextCardsString && prev.children === next.children
}

interface ProviderProps {
  cards: CardInDeckListFragment[]
  children: React.ReactNode
}

export const Provider = memo<ProviderProps>(function ProviderComponent({ cards, children }) {
  const [groupBy, setGroupBy] = useState(GroupingType.TYPE)

  const { commander, main, side } = useMemo(() => {
    const boards = {
      commander: [],
      main: [],
      side: [],
    } as Record<Board, Array<CardInDeckListFragment>>

    for (const card of cards) {
      boards[card.board].push(card)
    }

    return {
      ...boards,
      ...{
        mainBoardSize: [...boards.main, ...boards.commander].reduce(helpers.reduceCardCount, 0),
        sideBoardSize: boards.side.reduce(helpers.reduceCardCount, 0),
      },
    }
  }, [cards])

  const groups: Array<GroupType> = useMemo(() => {
    const commanderGroup: Array<GroupType> = commander.length
      ? [
          {
            name: {
              single: 'Commander',
              plural: 'Commanders',
            },
            cards: commander,
            size: commander.reduce(helpers.reduceCardCount, 0),
            board: Board.COMMANDER,
          },
        ]
      : []

    const sideboardGroup: Array<GroupType> = side.length
      ? [
          {
            name: {
              single: 'Sideboard',
              plural: 'Sideboard',
            },
            cards: side,
            size: side.reduce(helpers.reduceCardCount, 0),
            board: Board.SIDE,
          },
        ]
      : []

    switch (groupBy) {
      case GroupingType.SUBTYPE: {
        const subtypeGroups = helpers.groupBySubtype(main)
        return [...commanderGroup, ...subtypeGroups, ...sideboardGroup]
      }

      case GroupingType.TAGS: {
        const tagGroups = helpers.groupByTags(main)
        return [...commanderGroup, ...tagGroups, ...sideboardGroup]
      }

      default: {
        const typeGroups = helpers.groupByType(main)
        return [...commanderGroup, ...typeGroups, ...sideboardGroup]
      }
    }
  }, [groupBy, commander, main, side])

  return (
    <viewContext.Provider
      value={{
        groups,
        groupBy,
        setGroupBy,
      }}
    >
      {children}
    </viewContext.Provider>
  )
}, isCardListEqual)
