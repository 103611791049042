import { RepeatIcon } from '@chakra-ui/icons'
import { ButtonGroup, ButtonProps, Flex, IconButton, Text } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { ImgProps } from 'react-image'
import { CardBaseFragment, CardBaseWithInventoryStatsFragment } from 'types/graphql'
import { CardFoiler } from './CardFoiler'
import { CardImage, CardImageContainer } from './CardImage'
import { HiddenLabel } from './HiddenLabel'

interface MagicCardProps {
  card: Pick<CardBaseFragment, 'name' | 'images' | 'cardFaces' | 'prices'> & {
    inInventory?: CardBaseWithInventoryStatsFragment['inInventory']
  }
  width?: number | string
  height?: number | string
  showTransform?: boolean | 'left' | 'right'
  loadInventory?: boolean
  showPrices?: boolean
  showInventory?: boolean
  isFoil?: boolean
  highlightColor?: string
  addonsRight?: Array<React.ReactNode>
  addonsLeft?: Array<React.ReactNode>
  addonSize?: ButtonProps['size']
}

export const LoadingMagicCard = ({
  size = 128,
  ...imageProps
}: { size?: number } & Omit<ImgProps, 'src' | 'layout'>) => {
  const width = useMemo(() => size * 0.7156, [size])
  return (
    <CardImageContainer className="card-image-container" style={{ height: size, width }}>
      <CardImage className="card-image" {...imageProps} layout="fixed" width={width} height={size} src="" />
    </CardImageContainer>
  )
}

const getCardCount = (card: CardBaseWithInventoryStatsFragment) => {
  return card?.inInventory ? card.inInventory.count : '-'
}
const getCardFoilCount = (card: CardBaseWithInventoryStatsFragment) => {
  return card?.inInventory ? card.inInventory.foilCount : '-'
}

export const MagicCard = ({
  card,
  height,
  width = '100%',
  showTransform = true,
  showPrices = false,
  showInventory = false,
  isFoil = false,
  highlightColor,
  addonsRight,
  addonsLeft,
  addonSize,
  ...imageProps
}: MagicCardProps & Omit<ImgProps, 'src' | 'layout'>) => {
  const [transformed, setTransformed] = useState(false)

  useEffect(() => {
    setTransformed(false)
  }, [card])

  const useCardFaces = useMemo(
    () =>
      card.cardFaces &&
      card.cardFaces.length > 0 &&
      card.cardFaces.filter(
        (cf) =>
          cf?.images &&
          ([cf?.images?.png, cf?.images?.large, cf?.images?.small, cf?.images?.normal].filter((src) => src) as string[])
            .length > 0,
      ).length > 0,
    [card],
  )

  const sources = useMemo(
    () =>
      useCardFaces
        ? {
            front: [
              card.cardFaces?.[0]?.images?.normal,
              card.cardFaces?.[0]?.images?.png,
              card.cardFaces?.[0]?.images?.large,
              card.cardFaces?.[0]?.images?.small,
            ].filter((src) => src) as string[],
            back: [
              card.cardFaces?.[1]?.images?.normal,
              card.cardFaces?.[1]?.images?.png,
              card.cardFaces?.[1]?.images?.large,
              card.cardFaces?.[1]?.images?.small,
            ].filter((src) => src) as string[],
          }
        : {
            front: [card.images.normal, card.images.png, card.images.large, card.images.small].filter(
              (src) => src,
            ) as string[],
            back: [''],
          },
    [card, useCardFaces],
  )
  const frontSrc = sources?.front[0]
  const backSrc = sources?.back[0]

  const ableToTransform = !!useCardFaces

  const computedAddonsRight = useMemo(
    () =>
      [
        ...(addonsRight || []),
        ableToTransform && showTransform && showTransform !== 'left' ? (
          <IconButton
            aria-label="Transform card"
            onClick={() => setTransformed(!transformed)}
            icon={<RepeatIcon transform={`rotate(${transformed ? 90 : 360}deg)`} transition=".2s all" />}
          />
        ) : undefined,
      ].filter(Boolean),
    [addonsRight, ableToTransform, showTransform, transformed],
  )

  const computedAddonsLeft = useMemo(
    () =>
      [
        ...(addonsLeft || []),
        ableToTransform && showTransform === 'left' ? (
          <IconButton
            colorScheme="blue"
            aria-label="Transform card"
            onClick={() => setTransformed(!transformed)}
            icon={<RepeatIcon transform={`rotate(${transformed ? 90 : 360}deg)`} transition=".2s all" />}
          />
        ) : undefined,
      ].filter(Boolean),
    [addonsLeft, ableToTransform, showTransform, transformed],
  )

  const size = height ? { height } : { width }

  return (
    <>
      <CardImageContainer className="card-image-container" style={size}>
        <CardFoiler $foil={isFoil} $highlightColor={highlightColor} />
        <CardImage
          className="card-image"
          {...imageProps}
          alt={`${card.name}`}
          title={`${card.name}`}
          src={frontSrc}
          $face="front"
          $facing={transformed ? 'back' : 'front'}
        />
        <CardImage
          className="card-image"
          {...imageProps}
          alt={`${card.name}`}
          title={`${card.name}`}
          src={backSrc}
          $face="back"
          $facing={transformed ? 'back' : 'front'}
        />
        <HiddenLabel aria-hidden="true">{card.name}</HiddenLabel>
        {computedAddonsLeft && (
          <ButtonGroup
            style={{
              position: 'absolute',
              top: '12%',
              left: '8%',
              borderRadius: 4,
              zIndex: 6,
            }}
            size={addonSize || 'md'}
            gap={2}
            justifyContent="center"
            flexDirection="column"
            alignItems="flex-end"
          >
            {computedAddonsLeft}
          </ButtonGroup>
        )}
        {computedAddonsRight && (
          <ButtonGroup
            style={{
              position: 'absolute',
              top: '12%',
              right: '8%',
              borderRadius: 4,
              zIndex: 6,
            }}
            size={addonSize || 'md'}
            gap={2}
            justifyContent="center"
            flexDirection="column"
            alignItems="flex-end"
          >
            {computedAddonsRight}
          </ButtonGroup>
        )}
      </CardImageContainer>
      {showInventory && (
        <Flex justify="space-between" style={{ marginTop: 6 }}>
          <Text>Owned {getCardCount(card as CardBaseWithInventoryStatsFragment)}</Text>
          <Text>Foil {getCardFoilCount(card as CardBaseWithInventoryStatsFragment)}</Text>
        </Flex>
      )}
      {showPrices && (
        <Flex justify="space-between" style={{ marginTop: 6 }}>
          <Text>$ {card.prices.usd}</Text>
          <Text>$ {card.prices.usdFoil || '---'}</Text>
        </Flex>
      )}
    </>
  )
}
