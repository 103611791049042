import { css, styled } from 'styled-components'

export const HiddenLabel = styled.label<{ loading?: 'true' }>`
  z-index: 1000;
  color: transparent;
  font-size: 14px;
  position: absolute;
  padding: 6.75% 0 0 8%;
  width: 100%;
  letter-spacing: 0px;
  pointer-events: none;
  left: 0;
  top: 0;
  ${(props) =>
    props.loading &&
    css`
      background: hsla(0, 0%, 74.5%, 0.2);
      border-radius: 4px;
    `}
`
