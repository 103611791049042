import { Board } from '@helvault/types'
import { useAuthContext } from 'components/AuthContext'
import { ReactNode, createContext, useContext, useMemo } from 'react'
import { CardInDeckListFragment } from 'types/graphql'
import * as helpers from '../helpers'
import { DeckType } from '../types'
import { DeckContext } from './types'

export const context = createContext<DeckContext>({
  deck: {} as DeckType,
  isOwner: false,
  loading: true,
})

export const useDeck = () => {
  return useContext(context)
}

interface ProviderProps {
  children: ReactNode
  deck: DeckType
  loading: boolean
}

export const Provider: React.FC<ProviderProps> = ({ children, deck: _deck, loading }) => {
  const { user } = useAuthContext()

  const deck = useMemo(() => _deck, [_deck])

  const isOwner = !!(user && deck && user.id === deck?.createdBy.id)

  const sizes = useMemo(() => {
    const boards = {
      commander: [],
      main: [],
      side: [],
    } as Record<Board, Array<CardInDeckListFragment>>

    for (const card of deck.cards) {
      boards[card.board].push(card)
    }

    return {
      main: [...boards.main, ...boards.commander].reduce(helpers.reduceCardCount, 0),
      side: boards.side.reduce(helpers.reduceCardCount, 0),
    }
  }, [deck.cards])

  const deckWithChanges = { ...deck, ...sizes }

  return (
    _deck && (
      <context.Provider
        value={{
          deck: deckWithChanges,
          loading,
          isOwner,
        }}
      >
        {children}
      </context.Provider>
    )
  )
}
