import { ApolloClient, ApolloError, NormalizedCacheObject } from '@apollo/client'
import { WebConfig } from '@helvault/types'
import { useConfig } from '@helvault/ui-config'
import { obtainAccessToken, signout } from 'lib/auth'
import React, { createContext } from 'react'
import { SignedInUserQuery, useSignedInUserQuery } from 'types/graphql'

interface AuthContext {
  user?: SignedInUserQuery['me']
  loading?: boolean
  logout?: () => Promise<void> | void
  login?: () => Promise<void> | void
  error?: ApolloError
}

export const logout = async (client: ApolloClient<NormalizedCacheObject>) => {
  await client.clearStore()
  await client.resetStore()
}

export const login = async (config: WebConfig, client: ApolloClient<NormalizedCacheObject>) => {
  await obtainAccessToken(config)
  await client.clearStore()
  await client.resetStore()
}

export const context = createContext<AuthContext>({})

const { Provider: DefaultProvider } = context

export const Provider: React.FC<{ children?: React.ReactNode; onUserChange: () => void }> = ({ children }) => {
  const config = useConfig()
  const { data, loading, error, client, refetch } = useSignedInUserQuery({
    fetchPolicy: 'network-only',
  })

  return (
    <DefaultProvider
      value={{
        user: loading ? undefined : data?.me,
        logout: async () => {
          await signout(config)
          await logout(client)
        },
        login: async () => {
          await login(config, client)
          await refetch()
        },
        loading,
        error,
      }}
    >
      {children}
    </DefaultProvider>
  )
}

export const useAuthContext = () => {
  return React.useContext(context)
}
