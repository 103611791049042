import * as csstype from 'csstype'
import styled from 'styled-components'

const Flexbox = styled.div<FlexProps>`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => props.wrap};
`
export interface FlexProps {
  wrap?: csstype.Properties['flexWrap']
  direction?: csstype.Properties['flexDirection']
  align?: csstype.Properties['alignItems']
  justify?: csstype.Properties['justifyContent']
  flex?: csstype.Properties['flex']
}

type Props = FlexProps & React.HtmlHTMLAttributes<HTMLDivElement>
export type FlexComponentProps = Props

const Flex: React.FC<Props & { children?: React.ReactNode }> = ({ children, ...props }) => (
  <Flexbox {...props}>{children}</Flexbox>
)

export default Flex
