import { useIntervalEffect } from '@react-hookz/web'
import { useCallback, useMemo, useRef } from 'react'
import { toast } from 'sonner'

export interface VersionNotificationParams {
  /** Endpoint to use for fetching version */
  endpoint: string
  /** How often to check for new version (ms). Defaults to 30sec */
  interval?: number
  /** The message to display when a new version is available */
  message?: string
  /** button label. Button won't be visible if this is undefined */
  buttonText?: React.ReactNode
  /** Custom fetch function that returns server version */
  fetcher?: () => Promise<string>
}

const DEFAULTS = {
  interval: 3000,
  message: 'A new version is available',
  buttonText: 'Reload',
} as const

export const useVersionNotification = (
  /** Version notification params. Pass undefined to deactivate this */
  maybeParams?: VersionNotificationParams,
) => {
  const params: VersionNotificationParams | undefined = useMemo(
    () => (maybeParams ? { ...DEFAULTS, ...maybeParams } : undefined),
    [maybeParams],
  )

  /** Use a ref instead of setState to keep values as setting state makes upstream component to re-render */
  const clientVersion = useRef<null | string>(null)
  const versionChanged = useRef<boolean>(false)

  const fetchVersion = async () => {
    if (!params) throw new Error('No params given')
    if (params.fetcher) return params.fetcher()

    const res = await fetch(params.endpoint)
    const serverVersion = await res.text()
    return serverVersion
  }

  useIntervalEffect(async () => {
    if (versionChanged.current) return

    try {
      const serverVersion = await fetchVersion()

      if (clientVersion.current === null) {
        clientVersion.current = serverVersion
      } else if (clientVersion.current !== serverVersion) {
        versionChanged.current = true
        displayNotification()
      }
    } catch {
      console.warn('Unable to retrieve version information')
    }
  }, params?.interval)

  const displayNotification = useCallback(() => {
    if (!params) throw new Error('No params given')

    toast.custom(
      () => (
        <div className="animate-enter pointer-events-auto flex w-full max-w-md alert alert-info shadow-lg ring-opacity-5">
          <div className="w-0 flex-1">
            <div className="flex items-center">
              <div className="pt-0.5 text-2xl">🎉</div>
              <div className="ml-3 text-base font-medium text-neutral-800">{params.message}</div>
            </div>
          </div>
          <div className="flex">
            <button
              type="button"
              onClick={() => window.location.reload()}
              className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent btn btn-sm"
            >
              {params.buttonText}
            </button>
          </div>
        </div>
      ),
      { duration: Infinity, className: 'w-[500px]' },
    )
  }, [params])
}
