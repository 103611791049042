import { useState } from 'react'

import { ConfirmContext, State } from './ConfirmContext'
import { ConfirmModal } from './ConfirmModal'

const defaultState: State = {
  title: '',
  message: '',
  okText: 'Fortsett',
  cancelText: 'Avbryt',
  isOpen: false,
  proceed: null,
  cancel: null,
}

export const ConfirmProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [confirm, setConfirm] = useState<State>(defaultState)

  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      <ConfirmModal />
      {children}
    </ConfirmContext.Provider>
  )
}
