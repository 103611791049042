import { Board, CardFinish } from '@helvault/types'

export interface CardData {
  name: string
  set?: string
  collectorNumber?: string
  finish?: CardFinish
  count?: number
  board?: Board
}

export interface LineFormatConfig {
  name: string
  description: string
  toLine: (data: CardData) => string
  toData: (line: string) => CardData & { count: number }
}

const finishKeyToFinish: Record<string, CardFinish> = {
  F: CardFinish.FOIL,
  E: CardFinish.ETCHED,
  G: CardFinish.GLOSSY,
}

export enum LineFormat {
  // MTGO = 'MTGO',
  MOXFIELD = 'MOXFIELD',
  // TAPPEDOUT = 'TAPPEDOUT',
  // ARENA = 'ARENA',
}

const emptyResult = {
  count: 0,
  name: '',
}

export const formatConfigs: Record<LineFormat, LineFormatConfig> = {
  [LineFormat.MOXFIELD]: {
    name: 'Moxfield',
    description: '# Card name (SET_CODE) collectorNumber *finish*',
    toLine: (data: CardData) => {
      const { name, set, collectorNumber, finish, count = 1 } = data
      const parts = [count, name]

      if (set) {
        parts.push(`(${set.toUpperCase()})`)
      }

      if (collectorNumber) {
        parts.push(collectorNumber)
      }

      if (finish && ![CardFinish.NONFOIL, CardFinish.GLOSSY].includes(finish)) {
        parts.push(`*${finish[0].toUpperCase()}*`)
      }

      return parts.join(' ')
    },
    toData: (line) => {
      if (!line) {
        return emptyResult
      }

      const regex = /^(\d+x?)?\s?([^\(\*]+[\s?[^\(+]{0,})?(\s?\(\w+\))?\s?(\d+)?\s?(\*\w+\*)?$/
      const matches = line.trim().match(regex)

      if (!matches) {
        return emptyResult
      }

      const [_, count, name, setString, collectorNumber, finishString] = matches

      const setMatch = setString?.match(/\((.{1,})\)/)
      const finish = finishString
        ? finishKeyToFinish[finishString?.replace(/\*/g, '') as keyof typeof finishKeyToFinish]
        : undefined

      const set = setMatch ? setMatch[1] : undefined

      if (!name) {
        return emptyResult
      }

      return {
        count: count ? parseInt(count.replace('x', '').trim(), 10) : 1,
        name: name.trim(),
        set,
        collectorNumber,
        finish,
      }
    },
  },
}
