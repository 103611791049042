import { CheckIcon, CopyIcon } from '@chakra-ui/icons'
import {
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { capitalCase } from 'capital-case'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { DeckBaseFragment, Visibility, useCopyDeckToCollectionMutation } from 'types/graphql'

const visibilities = Object.values(Visibility)

interface Props {
  deck: DeckBaseFragment
}

const CloneDialog = ({ deck }: Props) => {
  const [copyDeck, { loading: submitting }] = useCopyDeckToCollectionMutation()
  const [cloneName, setCloneName] = useState(deck.name)
  const [visibility, setVisibility] = useState(visibilities[0])
  const [done, setDone] = useState(false)
  const navigate = useNavigate()

  const doClone = async () => {
    try {
      const promise = copyDeck({
        variables: {
          deckId: deck.id,
          name: cloneName,
          visibility,
          fromPrecon: deck.precon,
        },
      })

      toast.promise(
        promise,
        {
          pending: `Cloning to ${cloneName}`,
          error: 'Clone failed',
          success: 'Cloned sucessfully!',
        },
        { theme: 'dark' },
      )

      const res = await promise

      if (res.data) {
        navigate(`/decks/${res.data.copyDeck.id}`)
      }
    } catch (e) {
      console.error(e)
    }
    setDone(true)
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Button size="sm" leftIcon={<CopyIcon />}>
          Clone
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader>Clone {deck.name} to your decks?</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Stack gap={2}>
            <Stack gap={0.5}>
              <Text>Give your deck a name</Text>
              <Input
                isDisabled={submitting}
                value={cloneName}
                onChange={(changeEvent) => setCloneName(changeEvent.target.value)}
              />
            </Stack>
            <Stack gap={0.5}>
              <Text>Visibility</Text>
              <RadioGroup
                width="size-2000"
                marginTop="size-125"
                value={visibility}
                onChange={(val) => setVisibility(val as Visibility)}
              >
                <Stack direction="row">
                  {visibilities.map((vis) => (
                    <Radio key={vis} value={vis}>
                      {capitalCase(vis)}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </Stack>
          </Stack>
        </PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end">
          {done ? (
            <CheckIcon color="secondary.700" />
          ) : (
            <>
              {submitting ? (
                <Spinner size="sm" />
              ) : (
                <Button size="xs" isDisabled={cloneName.length === 0} onClick={doClone}>
                  Clone
                </Button>
              )}
            </>
          )}
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

export default CloneDialog
