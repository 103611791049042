import * as CSS from 'csstype'
import { Component, ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface StackProps {
  children: ReactNode
  gap: number
  style?: any
  className?: string
  wrap?: CSS.Properties['flexWrap']
  justify: CSS.Properties['justifyContent']
  align: CSS.Properties['alignItems']
  direction?: CSS.Properties['flexDirection']
  separator?: ReactNode
  grow?: boolean
}

const Wrapper = styled.div<StackProps>`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  flex-flow: row ${({ wrap }) => (wrap ? wrap : 'nowrap')};
  ${(props) =>
    props.grow &&
    css`
      flex-grow: 1;
    `}
  .child:not(:last-child) {
    margin-right: ${(props) => props.gap}px;
  }
  .child {
    ${(props) => (props.wrap ? `margin-bottom: ${props.gap}px` : null)};
  }
`

class Stack extends Component<StackProps, any> {
  public static defaultProps: Partial<StackProps> = {
    gap: 12,
    align: 'center',
    justify: 'flex-start',
  }

  public render() {
    const { children, style, className, gap, align, justify, separator, wrap, direction } = this.props
    if (!children) {
      return false
    }

    if (children.constructor !== Array) {
      return <div style={style}>{children}</div>
    }

    const kids = (children as ReactNode[]).flat().filter((child) => !!child)

    return (
      <Wrapper
        className={className}
        style={style}
        gap={gap}
        align={align}
        justify={justify}
        direction={direction}
        wrap={wrap}
      >
        {kids.map((child, i) => [
          <div key={i} className="child">
            {child}
          </div>,
          separator && child && i !== kids.length - 1 && (
            <span key={`${i}-sep`} style={{ opacity: 0.4, marginRight: gap }}>
              {separator}
            </span>
          ),
        ])}
      </Wrapper>
    )
  }
}

export default Stack
