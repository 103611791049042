import { Box } from '@chakra-ui/react'
import { FloatingPortal } from '@floating-ui/react'
import clsx from 'clsx'
import { HTMLAttributes, MouseEvent, forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import { floatingStyles } from '../../lib/useFloatingHelpers'
import { MenuContext, MenuItemContextInjection } from './MenuContext'

export type MenuItemChild = React.ReactNode | ((props: MenuItemContextInjection) => React.ReactNode)

export type MenuItemsProps = HTMLAttributes<HTMLDivElement>

export type MenuItemClick = (ev: MouseEvent<HTMLDivElement>, index: number) => void

export interface MenuItemProps extends Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'onClick'> {
  children?: MenuItemChild
  disabled?: boolean
  onClick?: MenuItemClick
}

export const MenuItems = forwardRef<HTMLDivElement, MenuItemsProps>(
  ({ children, className, ...props }, forwardedRef) => {
    const { id, open, dropdown } = useContext(MenuContext)
    const { x, y, strategy } = dropdown
    const ref = useRef<HTMLDivElement | null>()

    const defaultClassNames =
      'z-dropdown min-w-[200px] max-w-[400px] rounded-md bg-neutral-50 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'

    useImperativeHandle(forwardedRef, () => ref.current as HTMLDivElement)

    return (
      <FloatingPortal>
        {open && (
          <Box
            {...props}
            role="menu"
            tabIndex={0}
            className={clsx(defaultClassNames, className)}
            ref={(node) => {
              dropdown.refs.setFloating(node as HTMLElement)
              ref.current = node
            }}
            style={floatingStyles({ x, y, strategy })}
            id={id}
          >
            {children}
          </Box>
        )}
      </FloatingPortal>
    )
  },
)

export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(({ children, ...props }, forwardedRef) => {
  const { onClick, className, ...rest } = props
  const { activeIndex, listRef, setOpen, interactions } = useContext(MenuContext)
  const itemRef = useRef<HTMLDivElement | null>(null)
  const [index, setIndex] = useState(-1)

  const disabled = Boolean(props.disabled)
  const active = !disabled && index >= 0 && activeIndex === index
  const content = typeof children === 'function' ? children({ active, disabled, index }) : children

  const defaultClassNames = [
    disabled ? 'cursor-not-allowed text-neutral-600' : 'cursor-pointer',
    active && 'bg-primary',
    'flex items-center px-4 py-2 text-neutral-700 font-medium',
  ]

  useImperativeHandle(forwardedRef, () => itemRef.current as HTMLDivElement)

  return (
    <div
      {...rest}
      role="menuitem"
      aria-disabled={disabled}
      className={clsx(...defaultClassNames, className)}
      ref={(node) => {
        listRef.current[index] = node
        itemRef.current = node

        if (node?.parentNode) {
          const idx = Array.from(node.parentNode.children).indexOf(node)
          setIndex(idx)
        } else {
          setIndex(-1)
        }
      }}
      {...interactions.getItemProps({
        onClick(ev: MouseEvent<HTMLDivElement>) {
          if (disabled) return
          onClick?.(ev, index)
          setOpen(false)
        },
      })}
    >
      {content}
    </div>
  )
})
