import { Box, HStack, Heading, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { memo } from 'react'
import { styled } from 'styled-components'
import { Format } from 'types/graphql'
import { CardInDeck } from '../CardInDeck'
import { GroupType } from '../Contexts'

const nameSort = (a: { name: string }, b: { name: string }) => (a.name > b.name ? 1 : 0)
const cmcSort = (a: { cmc: number }, b: { cmc: number }) => (a.cmc > b.cmc ? 1 : 0)

interface DeckGroupProps {
  group: GroupType
  withInventory?: boolean
  smallView: boolean
  isOwner: boolean
  format?: Format | null
}

const CardGroupContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 140px;
  padding-right: 15px;
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
  > div {
    margin-top: -140px;
    display: inline-block;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, 180px);
    width: 100%;
    column-gap: 6px;
    row-gap: 18px;
    padding-top: 0;
    padding-right: 0;
    > div {
      margin-top: 0px;
    }
  }
`

const DeckGroupComponent = ({ group, withInventory, smallView, isOwner, format }: DeckGroupProps) => {
  const sorted = useMemo(() => {
    return group.cards.sort((a, b) => nameSort(a.card, b.card)).sort((a, b) => cmcSort(a.card, b.card))
  }, [group])

  return (
    <Box width={{ md: 'initial', base: '100%' }}>
      <HStack>
        <Heading is="h4" size="md">
          {group.size === 1 ? group.name.single : group.name.plural}
        </Heading>
        <Text>({group.size})</Text>
      </HStack>
      <CardGroupContainer>
        {sorted.map((c) => (
          <CardInDeck
            key={c.id}
            card={c}
            board={group.board}
            withInventory={withInventory}
            smallView={smallView}
            isOwner={isOwner}
            format={format}
          />
        ))}
      </CardGroupContainer>
    </Box>
  )
}

export const DeckGroup = memo(DeckGroupComponent)
