import { TableContainer } from '@chakra-ui/react'
import {
  SelectedFilters,
  Table,
  TableProps,
  TableSelectedRows,
  TableSortingState,
  defaultRowSelection,
} from '@helvault/ui'
import { TableFilters } from '@helvault/ui/src/components/Table/Table'
import { useEffect, useReducer } from 'react'
import { CardBaseFragment, CardBaseWithInventoryStatsFragment } from 'types/graphql'

export interface CardRow {
  id: string
  card: CardBaseFragment
  name: string
  set: CardBaseFragment['set']
  collectorNumber: string
  counts: CardBaseWithInventoryStatsFragment['inInventory']
  releasedAt: string
}

type OurTableProps = Omit<TableProps<CardRow>, 'selection' | 'pagination' | 'sorting' | 'filter'>

interface TableVariables {
  pagination: typeof defaultPagination
  sorting?: TableSortingState
  filters: SelectedFilters<CardRow>
  selection: TableSelectedRows
}

interface CardTableProps extends OurTableProps {
  id: string
  totalRows: number
  availableFilters?: TableFilters<CardRow>
  onVariablesChange?: (variables: TableVariables) => void
}

const pageSizes = [10, 20, 30, 40, 50]
const defaultPagination = { page: 0, pageSize: pageSizes[0] }

const initialState: TableVariables = {
  pagination: defaultPagination,
  sorting: undefined,
  filters: {},
  selection: defaultRowSelection(),
}

const reducer = (state: TableVariables, action: Partial<TableVariables>) => ({ ...state, ...action })

export const CardTable: React.FC<CardTableProps> = ({
  id,
  onVariablesChange,
  totalRows,
  availableFilters,
  ...props
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const updateVariables = (variables: Partial<TableVariables>) => {
    dispatch(variables)
    onVariablesChange?.({
      ...state,
      ...variables,
      // To reset page if we change filters or sorting
      ...(variables.filters || variables.sorting ? { pagination: defaultPagination } : {}),
    })
  }

  useEffect(() => {
    updateVariables(initialState)
  }, [id])

  return (
    <TableContainer overflowY="scroll">
      <Table
        {...props}
        selection={{
          selectedRows: state.selection,
          onChange: (selection) => updateVariables({ selection }),
        }}
        pagination={{
          totalRows,
          onPageChange: (newPagination) =>
            updateVariables({ pagination: { page: newPagination.pageIndex, pageSize: newPagination.pageSize } }),
          pageIndex: state.pagination.page,
          pageSize: state.pagination.pageSize,
          pageSizes,
        }}
        sorting={{
          ...state.sorting,
          onChange: (sorting) => updateVariables({ sorting }),
          sortableColumns: ['name', 'set', 'collectorNumber'],
        }}
        filter={
          availableFilters && {
            filters: availableFilters,
            activeFilters: state.filters,
            onFilterChange: (filters) => updateVariables({ filters }),
          }
        }
      />
    </TableContainer>
  )
}
