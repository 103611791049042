import { Button, Icon } from '@chakra-ui/react'
import GLogin, { GoogleLoginResponse } from 'react-google-login'

import { useConfig } from '@helvault/ui-config'
import { obtainRefreshToken } from 'lib/auth'
import { FcGoogle } from 'react-icons/fc'

const SignIn = ({ onSignIn }: { onSignIn: () => void }) => {
  const config = useConfig()

  const onSuccess = async (data: GoogleLoginResponse) => {
    // Handle successful sign in
    await obtainRefreshToken(config, data)

    onSignIn()
  }

  return (
    <>
      <GLogin
        clientId={config.googleClientId}
        buttonText="Login"
        onSuccess={(data) => {
          onSuccess(data as GoogleLoginResponse)
        }}
        onFailure={(e) => window.alert(`Google auth failed: ${JSON.stringify(e)}`)}
        render={(props) => (
          <Button
            size="sm"
            onClick={() => props.onClick()}
            isDisabled={props.disabled}
            leftIcon={<Icon as={FcGoogle} />}
          >
            Sign in
          </Button>
        )}
      />
    </>
  )
}

export default SignIn
