import { CheckCircleIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { formatTag } from 'lib/formatTag'
import { RefObject, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { Board, CardInclusionChangeType, CardLeadership, Format, useSetDeckCoverImageMutation } from 'types/graphql'
import { CardInDeckType, useModifyDeck } from './Contexts'
import { SwitchPrintDialog } from './SwitchPrintDialog'

interface Props {
  card: CardInDeckType
  board: Board
  isOpen?: boolean
  onOpen: () => void
  onClose: () => void
  isOwner?: boolean
  format?: Format | null
  overBG?: boolean
  onTagsOpen: () => void
}

export const CardInDeckMenu = ({ isOpen, onOpen, onClose, card, board, format, isOwner, onTagsOpen }: Props) => {
  const switchPrintDisclosure = useDisclosure()
  const { addChange } = useModifyDeck()
  const toast = useToast()
  const initialFocusRef = useRef<HTMLDivElement>()

  const [setCoverImage] = useSetDeckCoverImageMutation()
  const { id: deckId } = useParams<{ id: string }>()

  const { change: _, __typename, card: cardData, ...inclusionData } = card
  const data = {
    ...inclusionData,
    tags: inclusionData.tags.map((t) => formatTag(t)),
    board,
    cardId: card.card.id,
  }

  const count =
    card.change && card.change.__typename === 'CardInclusionChangeUpdate'
      ? card.change.count
      : card.change && card.change.__typename === 'CardInclusionChangeRemove'
      ? 0
      : card.count

  const handleMenuAction = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, key: string) => {
    e.preventDefault()
    e.stopPropagation()
    switch (key) {
      case 'addone': {
        await addChange({
          type: CardInclusionChangeType.UPDATE,
          data: {
            ...data,
            count: count + 1,
          },
          card: cardData,
          cardInDeck: card,
        })

        break
      }

      case 'removeone': {
        await addChange({
          type: CardInclusionChangeType.UPDATE,
          data: {
            ...data,
            count: count - 1,
          },
          card: cardData,
          cardInDeck: card,
        })

        break
      }

      case 'removecard': {
        await addChange({
          type: CardInclusionChangeType.REMOVE,
          data: card.id,
          cardInDeck: card,
        })

        break
      }

      case 'setcommander': {
        await addChange({
          type: CardInclusionChangeType.UPDATE,
          data: {
            ...data,
            board: Board.COMMANDER,
          },
          card: cardData,
          cardInDeck: card,
        })

        break
      }

      case 'setcover': {
        await setCoverImage({ variables: { deckId, cardId: card.card.id } })

        toast({
          title: 'Cover image changed',
          description: `${card.card.name} is now the cover image`,
          icon: <CheckCircleIcon />,
          colorScheme: 'secondary',
        })

        break
      }

      case 'switchprint': {
        switchPrintDisclosure.onOpen()
        break
      }

      case 'movetoside': {
        await addChange({
          type: CardInclusionChangeType.UPDATE,
          data: {
            ...data,
            board: Board.SIDE,
          },
          card: cardData,
          cardInDeck: card,
        })

        break
      }

      case 'movetomain': {
        await addChange({
          type: CardInclusionChangeType.UPDATE,
          data: {
            ...data,
            board: Board.MAIN,
          },
          card: cardData,
          cardInDeck: card,
        })

        break
      }

      case 'edittags': {
        onTagsOpen()
      }

      default: {
        break
      }
    }
  }

  useEffect(() => {
    if (isOpen && initialFocusRef.current) {
      const items = initialFocusRef.current.childNodes

      const firstChild = items.item(0) as HTMLButtonElement
      setTimeout(() => {
        firstChild.focus()
      }, 100)
    }
  }, [isOpen])

  return (
    <>
      <Menu isLazy lazyBehavior="unmount" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <MenuButton as={IconButton} size="xs" icon={<HamburgerIcon />} />
        <Portal>
          <MenuList ref={initialFocusRef as RefObject<HTMLDivElement>}>
            {isOwner && (
              <>
                <MenuItem onClick={(e) => handleMenuAction(e, 'addone')}>Add one</MenuItem>
                {count > 1 && <MenuItem onClick={(e) => handleMenuAction(e, 'removeone')}>Remove one</MenuItem>}
                <MenuItem onClick={(e) => handleMenuAction(e, 'removecard')}>Remove {count > 1 ? 'all' : ''}</MenuItem>
                <MenuItem onClick={(e) => handleMenuAction(e, 'switchprint')}>Switch print</MenuItem>
                <MenuItem onClick={(e) => handleMenuAction(e, 'edittags')}>Edit tags</MenuItem>
                {board !== 'commander' && (
                  <>
                    <MenuDivider />
                    <MenuItem onClick={(e) => handleMenuAction(e, board === 'main' ? 'movetoside' : 'movetomain')}>
                      Move to {board === 'main' ? 'Sideboard' : 'Mainboard'}
                    </MenuItem>
                  </>
                )}
                {format && card.card.leadership?.[format as keyof CardLeadership] && (
                  <MenuItem onClick={(e) => handleMenuAction(e, board !== 'commander' ? 'setcommander' : 'movetomain')}>
                    {board !== 'commander' ? 'Set as Commander' : 'Move to main'}
                  </MenuItem>
                )}
                <MenuItem onClick={(e) => handleMenuAction(e, 'setcover')}>Set as cover image</MenuItem>
                {/* <MenuItem onClick={(e) => handleMenuAction(e, 'details')}>View card details</MenuItem> */}
              </>
            )}
          </MenuList>
        </Portal>
      </Menu>

      <SwitchPrintDialog
        card={card}
        board={board}
        show={switchPrintDisclosure.isOpen}
        onHide={switchPrintDisclosure.onClose}
      />
    </>
  )
}
