import { useContext, useEffect, useState } from 'react'

import { ConfirmContext, DisplayOptions } from './ConfirmContext'

// Based on
// https://medium.com/@jaredloson/a-replacement-for-window-confirm-using-promises-and-react-hooks-cfc011e76a7a

export const useConfirm = () => {
  const [state, setConfirm] = useContext(ConfirmContext)
  const [needsCleanup, setNeedsCleanup] = useState(false)

  const confirm = async (message: DisplayOptions['message'], options?: Omit<DisplayOptions, 'message'>) => {
    const displayProps = options
    const promise = new Promise((resolve, reject) => {
      /* If confirm is called as a result of 'ESC' being pressed
       * this prevents the confirm to be immediately closed
       */
      setTimeout(() => {
        setConfirm({
          message,
          isOpen: true,
          proceed: resolve,
          cancel: reject,
          title: options?.title,
          okText: options?.okText ?? 'Ok',
          cancelText: options?.cancelText ?? 'Cancel',
        })
        setNeedsCleanup(true)
      })
    })

    const reset = () => {
      setConfirm({ isOpen: false, proceed: null, cancel: null, ...displayProps })
      setNeedsCleanup(false)
    }

    return promise
      .then(
        () => true,
        () => false,
      )
      .finally(reset)
  }

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => {
    return () => {
      if (state.cancel && needsCleanup) {
        state.cancel()
      }
    }
  }, [state, needsCleanup])

  return {
    ...state,
    confirm,
  }
}
