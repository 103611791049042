import { Box, Flex, HStack, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import ProfileButton from './ProfileButton'

const Header = () => {
  return (
    <Box padding="12px 20px" marginBottom={8} height="80px" bg="nord.1">
      <Flex justify="space-between" align="center" height="100%">
        <Link as={RouterLink} to="/" size="sm" color="nord.9">
          <h2 style={{ margin: 0 }}>Helvault</h2>
        </Link>
        <HStack>
          <Link as={RouterLink} to="/search" size="sm" color="nord.9">
            Search
          </Link>
          <ProfileButton />
        </HStack>
      </Flex>
    </Box>
  )
}

export default Header
