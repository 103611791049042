import { ColorModeScript } from '@chakra-ui/react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { ChakraWrapper, theme } from './components/Chakra'
import './index.css'
import './lib/nord.css'

const container = document.getElementById('root')

if (container) {
  const root = ReactDOM.createRoot(container)

  root.render(
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraWrapper toastOptions={{ defaultOptions: { position: 'top-left' } }}>
        <App />
      </ChakraWrapper>
    </>,
  )
}
