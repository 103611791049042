import { type ThemeConfig, extendTheme } from '@chakra-ui/react'
import { colors } from './colors'

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: true,
}

export const theme = extendTheme({
  config,
  colors,
  components: {
    Button: {
      defaultProps: {
        size: 'sm',
        colorScheme: 'blue',
      },
    },
    Input: {
      defaultProps: {
        bg: 'white',
      },
    },
    Modal: {
      baseStyle: {
        overlay: {
          backdropFilter: 'blur(4px)',
        },
      },
      defaultProps: {
        colorScheme: 'primary',
      },
    },
    Menu: {
      baseStyle: {
        list: {
          zIndex: 10,
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: 'nord.0',
        color: 'nord.4',
      },
      '::selection': {
        background: 'nord.2',
        color: 'nord.13',
      },
    },
  },
})
