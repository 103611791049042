import { useVersionNotification } from '@helvault/ui'
import { useConfig } from '@helvault/ui-config'

export const NewVersionNotification = () => {
  const config = useConfig()

  if (config.production) {
    useVersionNotification({ endpoint: '/_version' })

    return null
  }

  return null
}
