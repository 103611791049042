import * as CSS from 'csstype'
import styled from 'styled-components'

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const PageContainer = styled.div<{
  direction: CSS.Properties['flexDirection']
  wrap: CSS.Properties['flexWrap']
}>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => props.wrap};
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`

const Page = ({
  children,
  direction = 'row',
  wrap = 'wrap',
  style,
}: {
  children?: React.ReactNode
  direction?: CSS.Properties['flexDirection']
  wrap?: CSS.Properties['flexWrap']
  style?: React.CSSProperties
}) => {
  return (
    <PageWrapper>
      <PageContainer direction={direction} wrap={wrap} style={style}>
        {children}
      </PageContainer>
    </PageWrapper>
  )
}

export default Page
