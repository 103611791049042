export const colors = {
  blue: {
    50: '#d4dde9',
    100: '#b2c3d7',
    200: '#a1b6cf',
    300: '#90a9c6',
    400: '#809bbd',
    500: '#5e81ac',
    600: '#49688e',
    700: '#405c7d',
    800: '#384f6c',
    900: '#26374b',
  },
  red: {
    50: '#bf616a',
    100: '#e4bcc0',
    200: '#dcaaae',
    300: '#d5979d',
    400: '#ce858c',
    500: '#bf616a',
    600: '#a9444e',
    700: '#973d46',
    800: '#84363d',
    900: '#60272c',
  },
  orange: {
    50: '#fcf8f6',
    100: '#f0d8d0',
    200: '#e9c7bd',
    300: '#e3b7aa',
    400: '#dda796',
    500: '#d08770',
    600: '#c3674a',
    700: '#b75a3c',
    800: '#a45036',
    900: '#7e3e29',
  },
  neutral: {
    50: '#fbfbfc',
    100: '#f3f5f8',
    200: '#eceff4',
    300: '#e5e9f0',
    400: '#d8dee9',
    500: '#4c566a',
    600: '#434c5e',
    700: '#3b4252',
    800: '#2e3440',
    900: '#000000',
  },
  secondary: {
    50: '#ffffff',
    100: '#dfeceb',
    200: '#cfe2e2',
    300: '#bfd9d8',
    400: '#afcfce',
    500: '#8fbcbb',
    600: '#6fa9a8',
    700: '#5f9f9e',
    800: '#568f8e',
    900: '#436f6e',
  },
  nord: {
    0: '#2e3440',
    1: '#3b4252',
    2: '#434c5e',
    3: '#4c566a',
    4: '#d8dee9',
    5: '#e5e9f0',
    6: '#eceff4',
    7: '#8fbcbb',
    8: '#88c0d0',
    9: '#81a1c1',
    10: '#5e81ac',
    11: '#bf616a',
    12: '#d08770',
    13: '#ebcb8b',
    14: '#a3be8c',
    15: '#b48ead',
  },
}
