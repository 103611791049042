import { clsx } from 'clsx'
import { HTMLAttributes, forwardRef, useContext, useImperativeHandle, useRef } from 'react'
import { MenuContext } from './MenuContext'

export interface MenuButtonProps extends HTMLAttributes<HTMLButtonElement> {
  as?: string
  disabled?: boolean
}

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(({ children, ...props }, forwardedRef) => {
  const { as: Tag, className, ...rest } = props
  const { id, dropdown, interactions, open } = useContext(MenuContext)
  const ref = useRef<HTMLElement | null>()

  // We want the tag to always behave like a button, which the tabIndex helps with
  // So it's fairly safe to cast here.
  const TagName = (Tag || 'div') as 'button' | 'div' as 'button'

  const defaultClassNames = ['flex flex-grow flex-col', props.disabled ? 'pointer-events-none' : '']

  useImperativeHandle(forwardedRef, () => ref.current as HTMLButtonElement)

  return (
    <TagName
      {...rest}
      className={clsx(...defaultClassNames, className)}
      data-testid="menu-button"
      tabIndex={0}
      aria-controls={id}
      aria-haspopup={true}
      aria-expanded={open}
      {...interactions.getReferenceProps({
        ref: (node) => {
          dropdown.refs.setReference(node as HTMLElement)
          ref.current = node as HTMLElement
        },
      })}
    >
      {children}
    </TagName>
  )
})
