import { keyframes } from 'styled-components'

export const breathe = keyframes`
  0% {
    box-shadow: 0px 0px 6px -1px var(--chakra-colors-nord-10);
  }
  25% {
    box-shadow: 0px 0px 12px -1px var(--chakra-colors-nord-10);
  }
  50% {
    box-shadow: 0px 0px 18px -1px var(--chakra-colors-nord-10);
  }
  75% {
    box-shadow: 0px 0px 12px -1px var(--chakra-colors-nord-10);
  }
  100% {
    box-shadow: 0px 0px 6px -1px var(--chakra-colors-nord-10);
  }
`

export const shine = keyframes`
  0%{
    top: -100%;
    linear-gradient: (
      to bottom,
      transparent 0%,
      rgba(255, 255, 255, 0.1) 50%,
      transparent 100%
    );
  }
  50% {
    linear-gradient: (
      to bottom,
      transparent 0%,
      rgba(255, 255, 255, 0.4) 50%,
      transparent 100%
    );
  }
  100%{
    top: 150%;
    linear-gradient: (
      to bottom,
      transparent 0%,
      rgba(255, 255, 255, 0.1) 50%,
      transparent 100%
    );
  }
`

export const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`
