import {
  UseFloatingReturn,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react'
import { MutableRefObject, PropsWithChildren, createContext, useEffect, useId, useRef, useState } from 'react'
import { PlacementWithAuto, placementStrategy } from '../../lib/useFloatingHelpers'

export interface MenuItemContextInjection {
  active: boolean
  disabled: boolean
  index: number
}

export interface MenuContextInterface {
  id: string
  open: boolean
  dropdown: Omit<UseFloatingReturn<HTMLElement>, 'context'>
  interactions: ReturnType<typeof useInteractions>
  activeIndex: number | null
  listRef: MutableRefObject<(HTMLDivElement | null)[]>
  setOpen: (open: boolean) => void
}

export interface MenuProviderProps extends PropsWithChildren {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  preferredPlacement?: PlacementWithAuto
}

export const MenuContext = createContext<MenuContextInterface>({} as MenuContextInterface)

export const MenuProvider: React.FC<MenuProviderProps> = ({
  children,
  preferredPlacement,
  open: _open,
  onOpenChange: _onOpenChange,
}) => {
  const [open, setOpen] = useState(Boolean(_open))
  const [activeIndex, setActiveIndex] = useState<null | number>(null)
  const listRef = useRef<HTMLDivElement[]>([])
  const id = useId()
  const placement = placementStrategy(preferredPlacement)

  const middleware = [offset(6), shift({ padding: 5 }), flip()]

  const onOpenChange = (changedOpen: boolean) => {
    _onOpenChange?.(changedOpen)
    setOpen(changedOpen)
  }

  const { context: ctx, ...dropdown } = useFloating<HTMLElement>({
    open,
    placement,
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    onOpenChange: onOpenChange,
    middleware,
  })

  const interactions = useInteractions([
    useClick(ctx),
    useRole(ctx, { role: 'listbox' }),
    useDismiss(ctx),
    useFocus(ctx),
    useListNavigation(ctx, {
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      allowEscape: true,
      virtual: true,
      loop: true,
    }),
  ])

  const context: MenuContextInterface = {
    id,
    open,
    dropdown,
    interactions,
    activeIndex,
    listRef,
    setOpen: onOpenChange,
  }

  useEffect(() => {
    if (typeof _open === 'boolean') setOpen(_open)
  }, [_open])

  return <MenuContext.Provider value={context}>{children}</MenuContext.Provider>
}
