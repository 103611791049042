import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { useToast } from '@chakra-ui/react'
import { removeTypename } from 'lib/removeTypename'
import { createContext, memo, useContext, useMemo } from 'react'
import {
  AddBulkChangeMutationVariables,
  CardInDeckListFragment,
  CardInclusionChangeType,
  DeckBulkChangesFragment,
  useAddBulkChangeMutation,
  useBulkEditDeckMutation,
  useDiscardPendingChangesMutation,
} from 'types/graphql'
import { AddChangeData, ModifyDeckContext } from './types'

export const modifyDeckContext = createContext<ModifyDeckContext>({
  addChange: async () => {},
  clearPendingChanges: () => {},
  submitPendingChanges: async () => {
    return undefined
  },
  loading: false,
})

export const useModifyDeck = () => {
  return useContext(modifyDeckContext)
}

interface ProviderProps {
  deckId: string
  refetchQueryOnAdd: string
  bulkChanges?: DeckBulkChangesFragment | null
  children: React.ReactNode
}

export const Provider = memo<ProviderProps>(function ProviderComponent({
  deckId,
  refetchQueryOnAdd,
  bulkChanges: _bulkChanges,
  children,
}) {
  const [addBulkChange, { loading: addLoading }] = useAddBulkChangeMutation()
  const [submitBulkChanges, { loading: submitLoading }] = useBulkEditDeckMutation()
  const [discardBulkChanges, { loading: discardLoading }] = useDiscardPendingChangesMutation()
  const toast = useToast()

  const currentBulkChanges = useMemo(
    () => _bulkChanges ?? { id: deckId, additions: 0, removals: 0, totalUpdates: 0 },
    [_bulkChanges],
  )

  const getChangeData = (
    change: AddChangeData,
  ): {
    change: AddBulkChangeMutationVariables['change']
    cardInclusion: CardInDeckListFragment
    bulkChanges: DeckBulkChangesFragment
  } => {
    const bulkChanges = { ...currentBulkChanges, totalUpdates: currentBulkChanges.totalUpdates + 1 }

    switch (change.type) {
      case CardInclusionChangeType.ADD: {
        return {
          change: {
            add: change.data,
          },
          cardInclusion: {
            // Change to use inclusionId helper
            id: `${change.card.id}:${change.data.finish}:${change.data.board}`,
            card: change.card,
            ...change.data,
            tags: change.data.tags.map((tag) => ({
              id: `deck-${tag}`,
              name: tag,
              isGlobal: false,
            })),
            change: {
              type: CardInclusionChangeType.ADD,
              __typename: 'CardInclusionChangeAdd',
            },
          },
          bulkChanges: {
            ...bulkChanges,
            additions: bulkChanges.additions + change.data.count,
          },
        }
      }
      case CardInclusionChangeType.UPDATE: {
        const cardInDeck = change.cardInDeck
        const { cardId: _, id: __, ...data } = change.data

        const deltaCountChange = change.data.count - cardInDeck.count
        if (deltaCountChange > 0) {
          bulkChanges.additions += deltaCountChange
        } else if (deltaCountChange < 0) {
          bulkChanges.removals += deltaCountChange
        }

        return {
          change: {
            update: change.data,
          },
          cardInclusion: {
            ...cardInDeck,
            change:
              change.data.count === 0
                ? { type: CardInclusionChangeType.REMOVE, __typename: 'CardInclusionChangeRemove' }
                : change.data.count === cardInDeck.count
                ? null
                : {
                    __typename: 'CardInclusionChangeUpdate',
                    type: CardInclusionChangeType.UPDATE,
                    ...data,
                    tags: data?.tags?.map((tag) => ({
                      id: `deck-${tag}`,
                      name: tag,
                      isGlobal: false,
                    })),
                    card: change.card,
                  },
          },
          bulkChanges,
        }
      }
      case CardInclusionChangeType.REMOVE: {
        const cardInDeck = change.cardInDeck

        return {
          change: {
            remove: change.data,
          },
          cardInclusion: {
            ...cardInDeck,
            change: {
              type: CardInclusionChangeType.REMOVE,
              __typename: 'CardInclusionChangeRemove',
            },
          },
          bulkChanges: {
            ...bulkChanges,
            removals: bulkChanges.removals + cardInDeck.count,
          },
        }
      }
    }
  }

  const addChange: ModifyDeckContext['addChange'] = async (change) => {
    const { change: changeVariable, bulkChanges, cardInclusion } = getChangeData(change)

    await addBulkChange({
      variables: { deckId, change: removeTypename(changeVariable) },
      optimisticResponse: {
        __typename: 'Mutation',
        addBulkChange: {
          __typename: 'BulkChangeResult',
          bulkChanges,
          cardInclusion,
        },
      },
      refetchQueries: changeVariable.add || changeVariable.remove ? [refetchQueryOnAdd] : undefined,
    })
  }

  const clearPendingChanges = async () => {
    await discardBulkChanges({
      variables: { deckId },
      // optimisticResponse: {
      //   discardPendingChanges: {
      //     id: deckId,
      //     bulkChanges: null,
      //     cards: deck.cards.map(({ change: _, ...c }) => c),
      //   },
      // },
    })
  }

  const submitPendingChanges = async () => {
    if (currentBulkChanges) {
      const res = await submitBulkChanges({
        variables: {
          deckId: deckId,
        },
      })

      if (res.errors) {
        toast({
          colorScheme: 'red',
          icon: <CloseIcon />,
          title: 'Failed',
          description: res.errors.map((err) => err.message).join(' | '),
        })
      } else {
        clearPendingChanges()

        toast({
          colorScheme: 'secondary',
          icon: <CheckIcon />,
          title: 'Success',
          description: `+${currentBulkChanges.additions}/-${currentBulkChanges.removals} changes committed`,
        })
      }
    }
  }

  return (
    <modifyDeckContext.Provider
      value={{
        addChange,
        clearPendingChanges,
        submitPendingChanges,
        loading: addLoading || submitLoading || discardLoading,
      }}
    >
      {children}
    </modifyDeckContext.Provider>
  )
})
