import { IconAlertOctagon, IconConfetti } from '@tabler/icons-react'
import clsx from 'clsx'
import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { serializeError } from 'serialize-error'

const ErrorPage: React.FC<{ children: React.ReactNode; background?: boolean } & React.HTMLAttributes<HTMLDivElement>> =
  ({ children, className, background, ...props }) => (
    <div
      className={clsx(
        'w-full h-full',
        background && 'text-center bg-cover bg-center bg-no-repeat text-neutral-50',
        className,
      )}
      {...props}
    >
      <div className="grid place-items-center min-h-screen ">
        <div>{children}</div>
      </div>
    </div>
  )

const NewVersion: React.FC = () => (
  <ErrorPage background={true}>
    <div className="text-4xl font-bold flex flex-col items-center gap-2">
      <IconConfetti className="h-32 w-32 text-status-info-50" />
      <span>New version</span>
    </div>

    <div className="mt-10 mb-10">A new version of SpellStack is available.</div>

    <div className="text-center">
      <button className="btn btn-primary" onClick={() => window.location.reload()}>
        Reload the page
      </button>
    </div>
  </ErrorPage>
)

interface NotFoundProps {
  text?: string
}

export const NotFound: React.FC<NotFoundProps> = ({ text = 'This page was not found' }) => {
  return (
    <ErrorPage background={true}>
      <div className="text-6xl font-bold mb-2 mt-1">404</div>
      <div className="mb-8">{text}</div>
      <Link to="/">
        <button className="btn btn-primary">Back to safety</button>
      </Link>
    </ErrorPage>
  )
}

export const ErrorBoundary: React.FC = () => {
  const error = useRouteError()
  const err = serializeError(error)

  const notFoundErrors = [
    /Failed to fetch dynamically imported module/, // Chrome
    /Importing a module script failed/, // Safari in development
    /'text\/html' is not a valid JavaScript MIME type/, // Safari in production
  ]

  if (err.name === 'TypeError' && notFoundErrors.some((pattern) => pattern.test(err.message ?? ''))) {
    return <NewVersion />
  }

  if (isRouteErrorResponse(error)) {
    return <NotFound />
  }

  return (
    <ErrorPage>
      <div>
        <div className="text-2xl font-bold mt-1 flex items-center flex-col">
          <IconAlertOctagon className="h-32 w-32  text-status-error-100" />
          An error occurred
        </div>

        <div className="space-y-2 mt-20 text-xs whitespace-pre-line font-mono overflow-x-auto">
          {err.name && <div>Name: {err.name}</div>}
          {err.code && <div>Code: {err.code}</div>}
          {err.message && <div>Message: {err.message}</div>}
          {err.stack && <div>{err.stack}</div>}
        </div>
      </div>
    </ErrorPage>
  )
}
