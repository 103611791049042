import { Board, CardFinishEnum, CardInDeckListFragment, CardInclusionChangeType } from 'types/graphql'
import { CardInDeckType, CardWithChange, GroupType } from './Contexts/types'
import { DeckType } from './types'

export const getChangeColor = (type: CardInclusionChangeType) => {
  switch (type) {
    case CardInclusionChangeType.REMOVE: {
      return 'var(--nord11)'
    }
    case CardInclusionChangeType.UPDATE: {
      return 'var(--nord13)'
    }
    case CardInclusionChangeType.ADD: {
      return 'var(--nord14)'
    }
  }
}

export const findCardInDeck = (deck: DeckType, id: string, finish?: CardFinishEnum): CardInDeckType | undefined => {
  const idWithoutPrefix = id.replace(`${deck.id}-`, '')
  const cardsInDeck = deck.cards
  return cardsInDeck.find(
    (c) =>
      (c.id === idWithoutPrefix || c.card.id === idWithoutPrefix || c.card.oracleId === idWithoutPrefix) &&
      (!finish || c.finish === finish),
  )
}

export const reduceCardCount = (sum: number, next: CardWithChange) => {
  if (next.change) {
    if (next.change?.__typename === 'CardInclusionChangeUpdate') {
      return sum + next.change.count
    } else {
      return sum
    }
  }

  return sum + next.count
}

const cardTypes = [
  'Planeswalker',
  'Battle',
  'Creature',
  'Land',
  'Sorcery',
  'Instant',
  'Artifact',
  'Enchantment',
] as const

const cardTypeSortOrder = [
  'Planeswalker',
  'Battle',
  'Creature',
  'Sorcery',
  'Instant',
  'Artifact',
  'Enchantment',
  'Land',
] as const

const pluralizedCardTypes = {
  Planeswalker: 'Planeswalkers',
  Battle: 'Battles',
  Creature: 'Creatures',
  Sorcery: 'Sorceries',
  Instant: 'Instants',
  Land: 'Lands',
  Artifact: 'Artifacts',
  Enchantment: 'Enchantments',
}

export const groupByType = (board: CardInDeckListFragment[]): Array<GroupType> => {
  let exhaustedCards: CardInDeckListFragment[] = []

  const groups: Array<GroupType> = []

  const getLandsBonus = (): Pick<GroupType, 'bonusCards' | 'bonusSize'> => {
    const countsForLands = exhaustedCards.filter(
      (c) =>
        c.card.cardFaces &&
        c.card.cardFaces.length > 1 &&
        c.card.cardFaces.some((face) => face?.typeLine.includes('Land')),
    )

    return {
      bonusCards: countsForLands,
      bonusSize: countsForLands.reduce(reduceCardCount, 0),
    }
  }

  for (const cardType of cardTypes) {
    const matchedCards = board.filter(
      (c) => c.card.types.includes(cardType) && !exhaustedCards.find((ec) => ec.id === c.id),
    )

    const isLands = cardType === 'Land'

    if (matchedCards.length > 0) {
      groups.push({
        cards: matchedCards,
        size: matchedCards.reduce(reduceCardCount, 0),
        board: Board.MAIN,
        name: {
          single: cardType,
          plural: pluralizedCardTypes[cardType],
        },
        ...(isLands ? getLandsBonus() : {}),
        sortOrder: cardTypeSortOrder.indexOf(cardType),
      })
    }

    exhaustedCards = [...exhaustedCards, ...matchedCards]
  }

  return groups.sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
}

export const groupBySubtype = (board: CardInDeckListFragment[]): Array<GroupType> => {
  const groups: Array<GroupType> = []
  const noSubtypeGroup: GroupType = {
    name: {
      single: 'No subtypes',
      plural: 'No subtypes',
    },
    cards: [],
    size: 0,
    board: Board.MAIN,
  }

  for (const card of board) {
    if (card.card.subtypes.length) {
      for (const subtype of card.card.subtypes) {
        const existingGroupIndex = groups.findIndex((g) => g.name.single === subtype)

        if (existingGroupIndex > -1) {
          groups[existingGroupIndex].cards.push(card)
          groups[existingGroupIndex].size += card.count
        } else {
          groups.push({
            name: {
              single: subtype,
              plural: subtype,
            },
            cards: [card],
            size: card.count,
            board: Board.MAIN,
          })
        }
      }
    } else {
      noSubtypeGroup.cards.push(card)
      noSubtypeGroup.size += card.count
    }
  }

  return noSubtypeGroup.size ? [...groups, noSubtypeGroup] : groups
}

export const groupByTags = (board: CardInDeckListFragment[]): Array<GroupType> => {
  const groups: Array<GroupType> = []
  const untaggedGroup: GroupType = {
    name: {
      single: 'Untagged',
      plural: 'Untagged',
    },
    cards: [],
    size: 0,
    board: Board.MAIN,
  }

  for (const card of board) {
    const tags = [
      ...new Set(
        [...card.tags, ...(card.change && 'tags' in card.change ? card.change?.tags || [] : [])].map((t) => t.name),
      ),
    ]

    if (tags.length) {
      for (const tag of tags) {
        const existingGroupIndex = groups.findIndex((g) => g.name.single === tag)

        if (existingGroupIndex > -1) {
          groups[existingGroupIndex].cards.push(card)
          groups[existingGroupIndex].size += card.count
        } else {
          groups.push({
            name: {
              single: tag,
              plural: tag,
            },
            cards: [card],
            size: card.count,
            board: Board.MAIN,
          })
        }
      }
    } else {
      untaggedGroup.cards.push(card)
      untaggedGroup.size += card.count
    }
  }

  return untaggedGroup.size ? [...groups, untaggedGroup] : groups
}
