import 'react-toastify/dist/ReactToastify.min.css'

import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { Flex, Spinner, Text } from '@chakra-ui/react'
import { ConfirmProvider } from '@helvault/ui'
import { ConfigProvider, useConfig } from '@helvault/ui-config'
import { NewVersionNotification } from 'components/NewVersionNotification'
import { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import Root from './Root'
import { AuthProvider } from './components/AuthContext'
import { createApolloClient } from './lib/graphql/client'

const Providers: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const config = useConfig()
  const [loggedInChange, setLoggedInChange] = useState(new Date())
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null)

  useEffect(() => {
    const makeClient = async () => {
      const apolloClient = await createApolloClient(config)
      setClient(apolloClient)
    }

    makeClient()
  }, [loggedInChange])

  return client ? (
    <IntlProvider locale="en">
      <ApolloProvider client={client}>
        <ConfirmProvider>
          <AuthProvider onUserChange={() => setLoggedInChange(new Date())}>{children}</AuthProvider>
        </ConfirmProvider>
      </ApolloProvider>
    </IntlProvider>
  ) : null
}

const App = () => {
  return (
    <ConfigProvider
      loading={
        <Flex w="full" h="full" flexDirection="column" gap={2} alignItems="center" justifyContent="center">
          <Spinner size="xl" />
          <Text>Establishing connection with server</Text>
        </Flex>
      }
    >
      <NewVersionNotification />
      <Providers>
        <Root />
      </Providers>
    </ConfigProvider>
  )
}

export default App
