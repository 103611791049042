import { Center, Progress, Stack, Text } from '@chakra-ui/react'
import Page from './Page'

interface Props {
  text?: string
}

export const CenteredPageLoading = ({ text = 'Loading...' }: Props) => {
  return (
    <Page>
      <Center height="100vh" width="100%">
        <Stack gap={0.5} width="100%" maxWidth="lg">
          <Progress size="xs" isIndeterminate />
          <Center>
            <Text>{text}</Text>
          </Center>
        </Stack>
      </Center>
    </Page>
  )
}
