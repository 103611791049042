import { Box, Center } from '@chakra-ui/react'
import { cardBorderRadius } from 'components/MagicCard'
import { breathe } from 'lib/keyframes'
import { css, styled } from 'styled-components'

export const ShowOnHover = styled(Center)`
  transition: opacity 0.2s ease-in-out;
`

export const CardContainer = styled(Box)<{ $forceShow?: boolean; $blink?: boolean }>`
  border-radius: ${cardBorderRadius};
  &:hover, &:focus, &:focus-within {
    ${ShowOnHover} {
      opacity: 1;
    }
  }
  ${(props) => css`
    ${
      props.$forceShow
        ? css`${ShowOnHover} {
        opacity: 1;
      }`
        : ''
    }
    ${
      props.$blink
        ? css`
      z-index: 10;
      animation: ${breathe} 2s linear infinite
      `
        : ''
    }
  `}
`
