import {
  AddCardToDeckInput,
  Board,
  CardBaseFragment,
  CardInDeckListFragment,
  CardInclusionChangeType,
  UpdateCardInDeckInput,
} from 'types/graphql'
import { DeckType } from '../types'

export enum GroupingType {
  TYPE = 'TYPE',
  SUBTYPE = 'SUBTYPE',
  TAGS = 'TAGS',
}

interface NameWithPlural {
  single: string
  plural: string
}

export interface GroupType {
  name: NameWithPlural
  size: number
  cards: Array<CardWithChange>
  bonusSize?: number
  bonusCards?: Array<CardWithChange>
  board: Board
  sortOrder?: number
}

export type AddChange = { type: CardInclusionChangeType.ADD; data: AddCardToDeckInput; card: CardBaseFragment }
export type UpdateChange = { type: CardInclusionChangeType.UPDATE; data: UpdateCardInDeckInput; card: CardBaseFragment }
export type RemoveChange = { type: CardInclusionChangeType.REMOVE; data: string }

export type ChangeData = AddChange | UpdateChange | RemoveChange

export type AddChangeData =
  | AddChange
  | (UpdateChange & {
      cardInDeck: CardInDeckListFragment
    })
  | (RemoveChange & {
      cardInDeck: CardInDeckListFragment
    })

export type CardWithChange = CardInDeckListFragment

export type DeckWithDeltaChanges = Omit<DeckType, 'cards'> & { cards: Array<CardWithChange> }

export type CardInDeckType = CardWithChange

export interface DeckContext {
  deck: DeckWithDeltaChanges
  loading: boolean
  isOwner: boolean
}

export interface ModifyDeckContext {
  addChange: (change: AddChangeData) => Promise<void>
  clearPendingChanges: () => void
  submitPendingChanges: () => Promise<void>
  loading: boolean
}

export interface DeckViewContext {
  groups: Array<GroupType>

  groupBy: GroupingType
  setGroupBy: (groupBy: GroupingType) => void
}
