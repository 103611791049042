import { Card as MTGJSONCard, Deck as DeckType, SetType as MTGJSONSet } from '@helvault/mtgjson'
import { ScryfallCard, ScryfallCardFace, ScryfallImageUris, ScryfallSet } from '@helvault/scryfall'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ColorEnum } from './Color'
import { CardInclusionInDeck } from './aggregates'

export enum CardFinish {
  ETCHED = 'etched',
  FOIL = 'foil',
  GLOSSY = 'glossy',
  NONFOIL = 'nonfoil',
  SIGNED = 'signed',
}

export interface CardInclusionInDeckRow extends CardInclusionInDeck {
  id: string
}

export interface NormalizedCard
  extends CamelCasedPropertiesDeep<
    Pick<
      ScryfallCard,
      | 'object'
      | 'arena_id'
      | 'id'
      | 'lang'
      | 'mtgo_id'
      | 'mtgo_foil_id'
      | 'multiverse_ids'
      | 'tcgplayer_id'
      | 'oracle_id'
      | 'prints_search_uri'
      | 'rulings_uri'
      | 'scryfall_uri'
      | 'uri'
      | 'all_parts'
      | 'card_faces'
      | 'cmc'
      | 'colors'
      | 'color_identity'
      | 'color_indicator'
      | 'edhrec_rank'
      | 'hand_modifier'
      | 'layout'
      | 'legalities'
      | 'life_modifier'
      | 'loyalty'
      | 'mana_cost'
      | 'name'
      | 'oracle_text'
      | 'oversized'
      | 'power'
      | 'reserved'
      | 'toughness'
      | 'type_line'
      | 'artist'
      | 'booster'
      | 'border_color'
      | 'card_back_id'
      | 'collector_number'
      | 'digital'
      | 'flavor_text'
      | 'frame_effects'
      | 'frame'
      | 'full_art'
      | 'games'
      | 'highres_image'
      | 'illustration_id'
      | 'printed_name'
      | 'printed_text'
      | 'printed_type_line'
      | 'promo'
      | 'promo_types'
      | 'purchase_uris'
      | 'rarity'
      | 'related_uris'
      | 'released_at'
      | 'reprint'
      | 'scryfall_set_uri'
      | 'set_name'
      | 'set_search_uri'
      | 'set_type'
      | 'set_uri'
      | 'set'
      | 'story_spotlight'
      | 'textless'
      | 'variation'
      | 'variation_of'
      | 'watermark'
      | 'preview'
      | 'image_uris'
      | 'prices'
    >
  > {
  /* Poor mans extend with camelCasing */
  finishes: CardFinish[]
  leadershipSkills?: { brawl: boolean; commander: boolean; oathbreaker: boolean } | null
  isAlternative?: boolean
  isFullArt?: boolean
  isOnlineOnly?: boolean
  isOversized?: boolean
  isPromo?: boolean
  isReprint?: boolean
  isReserved?: boolean
  isStarter?: boolean
  isStorySpotlight?: boolean
  isTextless?: boolean
  isTimeshifted?: boolean
  isToken?: boolean
  hasContentWarning?: boolean
  hasFoil?: boolean
  hasAlternativeDeckLimit?: boolean
  hasNonFoil?: boolean
  types: MTGJSONCard['types']
  supertypes: MTGJSONCard['supertypes']
  subtypes: MTGJSONCard['subtypes']
}

export type CardImages = Partial<CamelCasedPropertiesDeep<ScryfallImageUris>>
export type CardFace = Partial<CamelCasedPropertiesDeep<ScryfallCardFace>>

export interface PreconDeck extends Omit<DeckType, 'commander' | 'mainBoard' | 'sideBoard'> {
  id: string
  mainBoardSize: number
  sideBoardSize: number
  coverCard?: string
  updatedAt?: string
}

export interface SetType
  extends Pick<
    Partial<MTGJSONSet>,
    | 'keyruneCode'
    | 'isForeignOnly'
    | 'isFoilOnly'
    | 'isNonFoilOnly'
    | 'isOnlineOnly'
    | 'isPaperOnly'
    | 'isPartialPreview'
    | 'baseSetSize'
    | 'totalSetSize'
    | 'booster'
  > {
  id: ScryfallSet['id']
  code: ScryfallSet['code']
  mtgoCode: ScryfallSet['mtgo_code']
  tcgplayerId: ScryfallSet['tcgplayer_id']
  name: ScryfallSet['name']
  setType: ScryfallSet['set_type']
  releasedAt: ScryfallSet['released_at']
  blockCode: ScryfallSet['block_code']
  block: ScryfallSet['block']
  parentSetCode: ScryfallSet['parent_set_code']
}

export interface CardRow {
  id: string

  releasedAt: Date

  cmc: number

  arenaId?: number
  mtgoId?: number
  mtgoFoilId?: number
  tcgplayerId?: number
  edhrecRank?: number
  multiverseIds?: number

  lang: string
  oracleId: string
  printsSearchUri: string
  rulingsUri: string
  scryfallUri: string
  uri: string
  name: string
  typeLine: string
  cardBackId: string
  collectorNumber: string
  scryfallSetUri: string
  setName: string
  setSearchUri: string
  setUri: string
  set: string
  layout: string
  frame: string
  setType: string
  rarity: string
  borderColor: string

  colorIdentity: ColorEnum[]
  games: string[]
  finishes: CardFinish[]

  handModifier?: string
  lifeModifier?: string
  loyalty?: string
  manaCost?: string
  oracleText?: string
  power?: string
  toughness?: string
  artist?: string
  flavorText?: string
  illustrationId?: string
  printedName?: string
  printedText?: string
  printedTypeLine?: string
  variationOf?: string
  watermark?: string

  colors?: string[]
  colorIndicator?: string[]
  types?: string[]
  subtypes?: string[]
  supertypes?: string[]
  artistIds?: string[]
  promoTypes?: string[]
  frameEffects?: string[]

  oversized: boolean
  reserved: boolean
  booster: boolean
  digital: boolean
  fullArt: boolean
  highresImage: boolean
  promo: boolean
  reprint: boolean
  storySpotlight: boolean
  textless: boolean
  variation: boolean

  isAlternative?: boolean
  isFullArt?: boolean
  isOnlineOnly?: boolean
  isOversized?: boolean
  isPromo?: boolean
  isReprint?: boolean
  isReserved?: boolean
  isStarter?: boolean
  isStorySpotlight?: boolean
  isTextless?: boolean
  isTimeshifted?: boolean
  isToken?: boolean
  hasContentWarning?: boolean
  hasFoil?: boolean
  hasAlternativeDeckLimit?: boolean
  hasNonFoil?: boolean

  legalities: NormalizedCard['legalities']
  preview: NormalizedCard['preview']
  relatedUris: NormalizedCard['relatedUris']
  purchaseUris: NormalizedCard['purchaseUris']
  prices: NormalizedCard['prices']
  imageUris: NormalizedCard['imageUris']
  leadershipSkills: NormalizedCard['leadershipSkills']
  allParts: NormalizedCard['allParts']
  cardFaces: NormalizedCard['cardFaces']
}
