import { CheckIcon } from '@chakra-ui/icons'
import {
  Button,
  Checkbox,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Pagination } from '@helvault/ui'
import { Flex, MagicCard } from 'components'
import { useMemo, useState } from 'react'
import { Board, CardFinishEnum, CardInclusionChangeType, CardPrintsQuery, useCardPrintsQuery } from 'types/graphql'
import { CardInDeckType, useModifyDeck } from './Contexts'

interface Props {
  card: CardInDeckType
  board: Board
  show: boolean
  onHide: () => void
}

type Print = CardPrintsQuery['cardPrints']['items'][number]

const PAGE_SIZE = 10

export const SwitchPrintDialog = ({ card, board, show, onHide }: Props) => {
  const { addChange } = useModifyDeck()
  const [submitting, setSubmitting] = useState<{ id: string; finish: CardFinishEnum } | null>(null)

  const [onlyOwned, setOnlyOwned] = useState(true)
  const [page, setPage] = useState(0)

  const { data, loading, previousData } = useCardPrintsQuery({
    variables: { cardId: card.card.id, limit: PAGE_SIZE, offset: page * PAGE_SIZE, onlyOwned },
    skip: !show,
  })

  const cardPrints =
    loading && !previousData
      ? { total: 0, items: [] }
      : data?.cardPrints || previousData?.cardPrints || { total: 0, items: [] }
  const totalPages = useMemo(() => Math.ceil(cardPrints.total / PAGE_SIZE), [cardPrints.total])

  const prints = (cardPrints.items || []).sort(
    (a, b) => new Date(parseInt(b.set.releasedAt, 10)).getTime() - new Date(parseInt(a.set.releasedAt, 10)).getTime(),
  )

  const changeCardPrint = async (print: Print, finish: CardFinishEnum) => {
    setSubmitting({ id: print.id, finish })

    const count =
      card.change && card.change.__typename === 'CardInclusionChangeUpdate'
        ? card.change.count
        : card.change && card.change.__typename === 'CardInclusionChangeRemove'
        ? 0
        : card.count

    try {
      await addChange({
        type: CardInclusionChangeType.UPDATE,
        data: { id: card.id, cardId: print.id, board, finish, count },
        card: print,
        cardInDeck: card,
      })
      onHide()
    } catch {
      // Do stuff
    }

    setSubmitting(null)
  }

  const selectedId =
    card.change && card.change.__typename === 'CardInclusionChangeUpdate' && card.change.card
      ? card.change.card.id
      : card.card.id

  return (
    <Modal scrollBehavior="inside" size={{ sm: 'full', md: 'xl' }} isOpen={show} onClose={onHide}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Switch print for {card.card.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={4}>
            <HStack gap={2} align="center">
              <Checkbox isChecked={onlyOwned} onChange={(e) => setOnlyOwned(e.target.checked)} />
              <Text>Only show owned prints</Text>
            </HStack>

            {totalPages > 1 && (
              <Pagination
                totalPages={totalPages}
                pageSize={PAGE_SIZE}
                page={page}
                onChange={({ page }) => setPage(page)}
              />
            )}

            {loading ? (
              <Progress isIndeterminate />
            ) : (
              prints.map((print) => (
                <Stack key={print.id} direction="row" gap={2} marginBottom={6}>
                  <div style={{ position: 'relative' }}>
                    {print.id === selectedId && <CheckIcon position="absolute" zIndex={3} width="100%" height="100%" />}
                    <MagicCard width={128} card={print} />
                  </div>
                  <Stack width="100%">
                    <Flex style={{ width: '100%' }} justify="space-between" align="flex-start">
                      <Heading as="h3" size="md" marginStart="size-115" marginTop="size-0">
                        {print.set.name}
                      </Heading>
                      <Heading as="h4" size="sm" marginTop="size-0">
                        {new Date(parseInt(print.set.releasedAt, 10)).getFullYear()}
                      </Heading>
                    </Flex>
                    <Stack>
                      <Stack direction="row" gap={6}>
                        {print.finishes.map((finish) => (
                          <Stack key={finish}>
                            <Button
                              size="sm"
                              isDisabled={!!submitting || (print.id === selectedId && card.finish === finish)}
                              onClick={() => changeCardPrint(print, finish)}
                            >
                              {submitting && submitting.id === print.id && submitting.finish === finish ? (
                                <Spinner size="xs" />
                              ) : (
                                `Select${finish === CardFinishEnum.NONFOIL ? '' : ` ${finish}`}`
                              )}
                            </Button>
                            <Text>
                              Owned{' '}
                              {finish === CardFinishEnum.NONFOIL || finish === CardFinishEnum.SIGNED
                                ? print.inInventory.count
                                : print.inInventory[`${finish}Count`]}
                            </Text>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ))
            )}

            {totalPages > 1 && (
              <Pagination
                totalPages={totalPages}
                pageSize={PAGE_SIZE}
                page={page}
                onChange={({ page }) => setPage(page)}
              />
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
