import { Spinner as ChakraSpinner, Text as ChakraText } from '@chakra-ui/react'
import clsx from 'clsx'

export type LoadingProps = React.HTMLAttributes<HTMLOrSVGElement>

const Spinner = ChakraSpinner

interface LoadingOverlayProps {
  loading?: boolean
  className?: string
  loadingText?: string
  children?: React.ReactNode
}

const Text = ({
  className,
  children,
  spinner = true,
  ...props
}: LoadingProps & {
  spinner?: boolean
  children: React.ReactNode
}) => {
  return (
    <div
      data-testid="loading-text"
      className={clsx('inline-flex animate-pulse items-center gap-2', className)}
      {...props}
    >
      {spinner && <Spinner className="h-4 w-4" />}
      <ChakraText className="text-neutral-700">{children}</ChakraText>
    </div>
  )
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ children, className, loading, loadingText }) => {
  return (
    <div data-testid="loading-overlay" className={clsx(className, 'relative', loading && 'min-h-[2.5rem]')}>
      {loading && (
        <div className="absolute inset-0 z-loading-overlay bg-transparent backdrop-blur-sm">
          <div className="flex h-full max-h-96 items-center justify-center">
            {loadingText && <Text>{loadingText}</Text>}
            {!loadingText && <Spinner className="h-10 w-10" />}
          </div>
        </div>
      )}

      {children}
    </div>
  )
}

export const Loading = {
  Spinner,
  Text,
  Overlay: LoadingOverlay,
}
