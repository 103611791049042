import { Text, useDisclosure } from '@chakra-ui/react'
import { MagicCard } from 'components'
import { isEqual } from 'lodash'
import { MouseEventHandler, memo, useRef, useState } from 'react'
import { Board, CardFinishEnum, Format } from 'types/graphql'
import { CardInDeckMenu } from '../CardInDeckMenu'
import { CardInDeckType } from '../Contexts'
import { getChangeColor } from '../helpers'
import { useCardToShow } from '../hooks'
import { TagsModal } from './TagsModal'
import { CardContainer, ShowOnHover } from './styledComponents'

interface Props {
  card: CardInDeckType
  board: Board
  isOwner: boolean
  format?: Format | null
  withInventory?: boolean
  smallView?: boolean
  enableMenu?: boolean
}

const areEqual = (prevProps: Props, nextProps: Props) => {
  const { card: _, ...prevInclusion } = prevProps.card
  const { card: __, ...nextInclusion } = nextProps.card
  const isEq =
    isEqual(prevInclusion, nextInclusion) &&
    prevProps.card.id === nextProps.card.id &&
    prevProps.board === nextProps.board &&
    prevProps.withInventory === nextProps.withInventory &&
    prevProps.smallView === nextProps.smallView &&
    prevProps.enableMenu === nextProps.enableMenu &&
    prevProps.isOwner === nextProps.isOwner &&
    prevProps.format === nextProps.format

  return isEq
}

export const CardInDeck = memo<Props>(function CardInDeckComponent({
  card,
  board,
  withInventory,
  smallView,
  enableMenu = true,
  isOwner,
  format,
}) {
  const tagsModal = useDisclosure()
  const [forceFocus, setForceFocus] = useState(false)
  const cardContainer = useRef<HTMLDivElement>()

  const infoToShow = useCardToShow(card)

  const menu = useDisclosure()

  const onContainerClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.ctrlKey || e.metaKey) {
      tagsModal.onOpen()
      return
    }

    if (!smallView) {
      setForceFocus(true)
      localStorage.setItem('focus-forced', 'true')
      setTimeout(() => {
        setForceFocus(false)
        localStorage.removeItem('focus-forced')
      }, 3000)
    }
  }

  const onContextMenu: MouseEventHandler<HTMLDivElement> = (e) => {
    if (enableMenu && isOwner) {
      if (e) {
        e.preventDefault()
      }

      menu.onOpen()
    }
  }

  return (
    <>
      <CardContainer
        ref={cardContainer}
        data-focus-card-id={card.id}
        $forceShow={menu.isOpen}
        $blink={forceFocus}
        onContextMenu={onContextMenu}
        onClick={onContainerClick}
        position="relative"
      >
        {infoToShow.card && infoToShow.data && (
          <MagicCard
            card={infoToShow.card}
            isFoil={infoToShow.data.finish !== CardFinishEnum.NONFOIL}
            height={244}
            showTransform={smallView ? 'left' : false}
            showInventory={withInventory && smallView}
            highlightColor={card.change ? getChangeColor(card.change.type) : undefined}
            addonSize="xs"
            addonsLeft={
              enableMenu && isOwner
                ? [
                    <ShowOnHover key="card-menu" opacity={smallView ? 1 : 0}>
                      <CardInDeckMenu
                        card={card}
                        board={board}
                        format={format}
                        isOwner={isOwner}
                        isOpen={menu.isOpen}
                        onOpen={menu.onOpen}
                        onClose={menu.onClose}
                        onTagsOpen={tagsModal.onOpen}
                      />
                    </ShowOnHover>,
                  ]
                : undefined
            }
            addonsRight={[
              <ShowOnHover
                key="card-count"
                opacity={infoToShow.data.count !== 1 ? 1 : 0}
                bg="blue.200"
                height="24px"
                minWidth="24px"
                p={1}
                borderRadius="md"
              >
                <Text size="xs" pb="1" color="gray.800">
                  x{infoToShow.data.count}
                </Text>
              </ShowOnHover>,
            ]}
          />
        )}
      </CardContainer>
      <TagsModal card={card} isOpen={tagsModal.isOpen} onClose={tagsModal.onClose} />
    </>
  )
}, areEqual)
