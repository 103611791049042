import { Box, Image } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { aspectRatio, cardBorderRadius } from './utils'

export const CardImageContainer = styled(Box)`
  position: relative;
  border-radius: ${cardBorderRadius};
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.45);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.45);
  z-index: 1;
  aspect-ratio: ${aspectRatio};
`

export const CardImage = styled(Image)<{
  $face?: 'front' | 'back'
  $facing?: 'front' | 'back'
}>`
  display: block;
  height: 100%;
  width: 100%;
  border-radius: ${cardBorderRadius};
  margin: 0;
  background-color: transparent;
  background-image: repeating-linear-gradient(-55deg, #ddd, #ddd 5px, #ccc 5px, #ccc 10px);
  color: #000;
  z-index: 2;
  transform: ${(props) => `rotateY(${props.$face !== props.$facing ? '180deg' : '0deg'})`};
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
`
