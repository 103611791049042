import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebConfig } from '@helvault/types'
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist'
import { createClient as createWsClient } from 'graphql-ws'
import localforage from 'localforage'
import { StrictTypedTypePolicies } from 'types/apollo-helpers'

const typePolicies: StrictTypedTypePolicies = {
  Deck: {
    merge(_, incoming) {
      return incoming
    },
    fields: {
      cards: {
        merge(_, incoming) {
          return incoming
        },
      },
    },
  },
  Card: {
    merge(existing, incoming, { mergeObjects }) {
      return mergeObjects(existing, incoming)
    },
  },
  CardInclusionInDeck: {
    fields: {
      change: {
        merge(existing, incoming, { mergeObjects }) {
          if (incoming === null || existing === null) {
            return incoming
          }
          return mergeObjects(existing, incoming)
        },
      },
    },
  },
  ImageUrls: {
    merge(existing, incoming, { mergeObjects }) {
      return mergeObjects(existing, incoming)
    },
  },
  Binder: {
    merge(existing, incoming, { mergeObjects }) {
      return mergeObjects(existing, incoming)
    },
  },
}

export const createApolloClient = async (config: WebConfig) => {
  const httpLink = new HttpLink({
    uri: config.graphqlEndpoint,
    credentials: 'include',
  })

  const wsLink = new GraphQLWsLink(
    createWsClient({
      url: config.graphqlWSSEndpoint,
      retryAttempts: 60,
    }),
  )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)

      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },

    wsLink,

    httpLink,
  )

  const cache = new InMemoryCache({
    typePolicies,
  })

  await persistCache({
    cache,
    storage: new LocalForageWrapper(localforage),
  })

  const client = new ApolloClient({
    link: splitLink,
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'network-only',
        returnPartialData: true,
      },
    },
    connectToDevTools: true,
  })

  return client
}
