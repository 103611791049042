import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react'

export interface PaginationState {
  page: number
  pageSize: number
}

export interface PaginationProps extends PaginationState {
  totalPages: number
  onChange: (newState: PaginationState) => void
  pageSizes?: number[]
  endJumps?: boolean
  pageJumper?: boolean
  startsAtOne?: boolean
}

export const Pagination: React.FC<PaginationProps> = ({
  page,
  totalPages,
  pageSize,
  pageSizes,
  onChange,
  endJumps,
  pageJumper,
  startsAtOne,
}) => {
  const startPage = startsAtOne ? 1 : 0
  const endPage = startsAtOne ? totalPages : totalPages - 1

  return (
    <Flex justifyContent="space-between" m={4} alignItems="center">
      <Flex>
        {endJumps && (
          <Tooltip label="First Page">
            <IconButton
              aria-label="Go to first page"
              onClick={() => onChange({ page: startPage, pageSize: pageSize })}
              isDisabled={page === startPage || totalPages === 0}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
        )}
        <Tooltip label="Previous Page">
          <IconButton
            aria-label="Go to previous page"
            onClick={() => onChange({ page: page - 1, pageSize: pageSize })}
            isDisabled={page === startPage || totalPages === 0}
            icon={<ChevronLeftIcon h={6} w={6} />}
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center">
        <Text flexShrink="0" mr={8}>
          Page{' '}
          <Text fontWeight="bold" as="span">
            {Math.min(startsAtOne ? page : page + 1, totalPages)}
          </Text>{' '}
          of{' '}
          <Text fontWeight="bold" as="span">
            {totalPages}
          </Text>
        </Text>
        {pageJumper && (
          <>
            <Text flexShrink="0">Go to page:</Text>{' '}
            <NumberInput
              ml={2}
              mr={8}
              w={28}
              min={1}
              max={totalPages || 1}
              onChange={(_, value) => {
                onChange({ page: value ? (startsAtOne ? value : value - 1) : 0, pageSize: pageSize })
              }}
              defaultValue={startsAtOne ? page : page + 1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </>
        )}
        {pageSizes && (
          <Select
            w={32}
            value={pageSize}
            onChange={(e) => {
              onChange({ page: startPage, pageSize: Number(e.target.value) })
            }}
          >
            {pageSizes.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        )}
      </Flex>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            aria-label="Go to next page"
            onClick={() => onChange({ page: page + 1, pageSize: pageSize })}
            isDisabled={page === endPage || totalPages === 0}
            icon={<ChevronRightIcon h={6} w={6} />}
          />
        </Tooltip>
        {endJumps && (
          <Tooltip label="Last Page">
            <IconButton
              aria-label="Go to last page"
              onClick={() => onChange({ page: endPage, pageSize: pageSize })}
              isDisabled={page === endPage || totalPages === 0}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        )}
      </Flex>
    </Flex>
  )
}
