import { Grid, GridItem } from '@chakra-ui/react'
import { ErrorBoundary } from '@helvault/ui'
import { useConfig } from '@helvault/ui-config'
import { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Toaster } from 'sonner'
import { CenteredPageLoading, Header } from './components'

const Root = () => {
  const config = useConfig()

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <>
          <Helmet>
            <title>{`${config.pageTitle}`}</title>
          </Helmet>
          <GridItem area="header">
            <Header />
          </GridItem>
          <GridItem area="content">
            <Suspense fallback={<CenteredPageLoading />}>
              <Outlet />
            </Suspense>
          </GridItem>
        </>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: '/',
          lazy: async () => {
            const { LandingPage } = await import('./views/LandingPage')
            return { Component: LandingPage }
          },
        },
        {
          path: '/search/',
          lazy: async () => {
            const { SearchPage } = await import('./views/SearchPage')
            return { Component: SearchPage }
          },
        },
        {
          path: '/user/inventory',
          lazy: async () => {
            const { UserInventoryPage } = await import('./views/UserInventoryPage')
            return { Component: UserInventoryPage }
          },
        },
        {
          path: '/user/inventory/add-by-set/:setCode?',
          lazy: async () => {
            const { AddCardsBySetPage } = await import('./views/UserInventoryPage')
            return { Component: AddCardsBySetPage }
          },
        },
        {
          path: '/binders/:id',
          lazy: async () => {
            const { BinderPage } = await import('./views/BinderPage')
            return { Component: BinderPage }
          },
        },
        {
          path: '/decks/precons',
          lazy: async () => {
            const { PreconDecks } = await import('./views/PreconDecks')
            return { Component: PreconDecks }
          },
        },
        {
          path: '/decks/personal/build-list',
          lazy: async () => {
            const { UserDecksBuildList } = await import('./views/UserDecksBuildList')
            return { Component: UserDecksBuildList }
          },
        },
        {
          path: '/decks/personal',
          lazy: async () => {
            const { UserDecksPage } = await import('./views/UserDecksPage')
            return { Component: UserDecksPage }
          },
        },
        {
          path: '/decks/:id/*',
          lazy: async () => {
            const { DeckPage } = await import('./views/DeckPage')
            return { Component: DeckPage }
          },
        },
        {
          path: '/planechase/:gameId/*',
          lazy: async () => {
            const { PlanechasePage } = await import('./views/PlanechasePage')
            return { Component: PlanechasePage }
          },
        },
        {
          path: '/signout',
          lazy: async () => {
            const { SignoutPage } = await import('./views/SignoutPage')
            return { Component: SignoutPage }
          },
        },
      ],
    },
  ])

  return (
    <Grid minHeight="100vh" templateAreas={`"header""content"`} templateRows="80px 1fr">
      <RouterProvider router={router} />
      <Toaster position="top-left" theme="dark" hotkey={[]} />
    </Grid>
  )
}

export default Root
