import type { WebConfig } from '@helvault/types'
import { createContext, useEffect, useState } from 'react'

interface ConfigContextType {
  config: WebConfig
}

export const ConfigContext = createContext<ConfigContextType>({} as any)

const useFetchConfig = (url: string) => {
  const [isLoading, setIsLoading] = useState(true)
  const [config, setConfig] = useState<WebConfig | null>(null)

  useEffect(() => {
    let retryCount = 0
    const doFetch = async () => {
      try {
        const res = await fetch(url)

        if (res.ok) {
          const fetchedConfig = (await res.json()) as WebConfig
          setConfig(fetchedConfig)
          setIsLoading(false)
        }
      } catch {
        if (retryCount < 10) {
          console.log(`Could not fetch config, retrying in ${retryCount}s`)
          retryCount++
          setTimeout(() => doFetch(), retryCount * 1000)
        } else {
          console.error('Could not fetch config')
        }
      }
    }

    doFetch()
  }, [])

  return { isLoading, config }
}

const getApiEndpoint = () => {
  const hostname = window.location.hostname
  const protocol = window.location.protocol

  switch (true) {
    case hostname.includes('localhost'):
      return `${protocol}//localhost:3000`
    case hostname.includes('webapp'):
      return `${protocol}//${hostname.replace('webapp', 'api')}`
    default:
      return `${protocol}//api-${hostname}`
  }
}

export const ConfigProvider: React.FC<{ children: React.ReactNode; loading: React.ReactNode }> = ({
  children,
  loading,
}) => {
  const url = getApiEndpoint()

  const { isLoading, config } = useFetchConfig(`${url.replace(/\/$/, '')}/web-config`)

  if (!isLoading && !config) {
    return <p>Could not load config</p>
  }

  return isLoading || !config ? (
    <>{loading}</>
  ) : (
    <ConfigContext.Provider value={{ config }}>{children}</ConfigContext.Provider>
  )
}
