import { InfoIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Kbd,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { CreatableSelect } from 'chakra-react-select'
import { formatTag } from 'lib/formatTag'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { CardInclusionChangeType, useDeckAndGlobalTagsQuery } from 'types/graphql'
import { CardWithChange, useModifyDeck } from '../Contexts'

interface TagsModalPorps {
  card: CardWithChange
  isOpen: boolean
  onClose: () => void
}

export const TagsModal: React.FC<TagsModalPorps> = ({ card, isOpen, onClose }) => {
  const { addChange } = useModifyDeck()
  const { id: deckId } = useParams<{ id: string }>()
  const { data, loading } = useDeckAndGlobalTagsQuery({ variables: { deckId }, skip: !isOpen })
  const [inputValue, setInputValue] = useState('')

  const defaultValues = useMemo(() => {
    return {
      tags: [...card.tags, ...(card.change && 'tags' in card.change ? card.change.tags || [] : [])].map((tag) => {
        const formattedTag = formatTag(tag)
        return { label: formattedTag, value: formattedTag }
      }),
    }
  }, [isOpen])

  const { control, handleSubmit, reset, setFocus } = useForm({
    defaultValues,
  })

  const deckTags = useMemo(() => {
    return (
      data?.deck?.tags.map((tag) => {
        const formattedTag = formatTag(tag)
        return { label: formattedTag, value: formattedTag }
      }) || []
    )
  }, [data])

  const globalTags = useMemo(() => {
    return (
      data?.me?.globalTags?.map((tag) => {
        const formattedTag = formatTag(tag)
        return { label: formattedTag, value: formattedTag }
      }) || []
    )
  }, [data])

  const options = useMemo(() => {
    const groups = []

    if (deckTags.length > 0) {
      groups.push({ label: 'Deck tags', options: deckTags })
    }

    if (globalTags.length > 0) {
      groups.push({ label: 'Global tags', options: globalTags })
    }

    return groups
  }, [deckTags, globalTags])

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues)
    }
  }, [isOpen])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async ({ tags }: { tags: Array<{ label: string; value: string }> }) => {
    setIsSubmitting(true)
    const { card: magicCard, change: _, ...data } = card

    const tagsArray = tags.map((tag) => tag.value)

    await addChange({
      type: CardInclusionChangeType.UPDATE,
      card: magicCard,
      cardInDeck: card,
      data: {
        ...data,
        cardId: magicCard.id,
        tags: tagsArray,
      },
    })

    setIsSubmitting(false)
    onClose()
  }

  const startTag = (start: '#' | '#!') => {
    setInputValue(start)
    setFocus('tags')
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Change tags for {card.card.name}</ModalHeader>
        <ModalCloseButton isDisabled={isSubmitting} />
        <ModalBody>
          <FormControl>
            <FormLabel>Custom tags</FormLabel>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  inputValue={inputValue}
                  onInputChange={setInputValue}
                  isLoading={loading}
                  isMulti
                  isClearable
                  options={options}
                  isValidNewOption={(val) => val.startsWith('#')}
                  formatCreateLabel={(val) =>
                    `${val.startsWith('#!') ? 'Global tag: ' : 'Deck tag: '} ${val.replace(/^(#!|#)/g, '')}`
                  }
                />
              )}
            />

            <FormHelperText>
              <UnorderedList>
                <ListItem>
                  Use{' '}
                  <Button variant="outline" size="xs" onClick={() => startTag('#!')}>
                    #!
                  </Button>{' '}
                  for <b>global</b> tags
                </ListItem>
                <ListItem>
                  Use{' '}
                  <Button variant="outline" size="xs" onClick={() => startTag('#')}>
                    #
                  </Button>{' '}
                  for <b>deck-specific</b> tags
                </ListItem>
              </UnorderedList>
            </FormHelperText>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={2} flexWrap="wrap">
          <Text width="100%">
            <InfoIcon /> <Kbd>Ctrl + Click</Kbd> a card can open this modal
          </Text>
          <Button variant="outline" onClick={onClose} isDisabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" isLoading={isSubmitting}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
