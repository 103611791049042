import { useRef } from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import { useConfirm } from './useConfirm'

export const ConfirmModal: React.FC = () => {
  const { isOpen = false, proceed, cancel, title, message, okText, cancelText } = useConfirm()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const onCancel = () => cancel?.()
  const onConfirm = () => proceed?.()

  if (!isOpen) return null

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onCancel}
      leastDestructiveRef={cancelRef}
      isCentered
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        {title && <AlertDialogHeader>{title}</AlertDialogHeader>}
        <AlertDialogCloseButton />
        <AlertDialogBody mt={title ? undefined : 6}>{message}</AlertDialogBody>
        <AlertDialogFooter gap={2}>
          <Button variant="outline" ref={cancelRef} onClick={onCancel}>
            {cancelText}
          </Button>
          <Button colorScheme="red" onClick={onConfirm}>
            {okText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
