import { Avatar, Button, HStack, Hide, Link, Menu, MenuButton, MenuItem, MenuList, Spinner } from '@chakra-ui/react'
import { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { AuthContext } from '../AuthContext'
import SignIn from './SignIn'

const ProfileButton = () => {
  const { loading, user, login } = useContext(AuthContext)

  return loading ? (
    <Spinner aria-label="Loading user" />
  ) : (
    <div>
      {user ? (
        <HStack>
          <Hide below="sm">
            <HStack>
              <Link as={RouterLink} to="/decks/personal" size="sm" color="nord.9">
                Your decks
              </Link>
              <Link as={RouterLink} to="/user/inventory" size="sm" color="nord.9">
                Inventory
              </Link>
            </HStack>
          </Hide>
          <Menu>
            <MenuButton
              as={Button}
              size="sm"
              variant="secondary"
              leftIcon={<Avatar size="xs" name={user.name} src={user.imageUrl} />}
              color="nord.8"
            >
              Menu
            </MenuButton>
            <MenuList>
              <MenuItem as={RouterLink} to="/user/inventory">
                Inventory
              </MenuItem>
              <MenuItem as={RouterLink} to="/decks/personal">
                Decks
              </MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem as={RouterLink} to="/signout">
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      ) : (
        <SignIn
          onSignIn={() => {
            if (login) {
              login()
            }
          }}
        />
      )}
    </div>
  )
}

export default ProfileButton
