import { createContext } from 'react'

export interface DisplayOptions {
  title?: string
  message?: React.ReactNode
  okText?: string
  cancelText?: string
}

export interface State extends DisplayOptions {
  isOpen: boolean
  proceed: null | ((value?: unknown) => void)
  cancel: null | (() => void)
}

export type IContext = [State, React.Dispatch<React.SetStateAction<State>>]

export const ConfirmContext = createContext<IContext>({} as IContext)
