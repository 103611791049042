import { Placement, Strategy } from '@floating-ui/react'

export type PlacementWithAuto = Placement | 'auto'

export const placementStrategy = (placement?: PlacementWithAuto) =>
  !placement || placement === 'auto' ? undefined : placement

export const floatingStyles = ({ x, y, strategy }: { strategy: Strategy; x: number | null; y: number | null }) => ({
  position: strategy,
  top: typeof y === 'number' ? `${y}px` : undefined,
  left: typeof x === 'number' ? `${x}px` : undefined,
})

export const arrowStyles = (
  arrow: { x?: number; y?: number } | undefined,
  placement: Placement,
): React.CSSProperties => {
  if (!arrow) return {}

  const side = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placement.split('-')[0]]

  if (!side) throw new Error(`Unable to calculate arrow side for placement ${placement}`)

  return {
    top: typeof arrow.y === 'number' ? `${arrow.y}px` : undefined,
    left: typeof arrow.x === 'number' ? `${arrow.x}px` : undefined,
    position: 'absolute',
    [side]: '-4px',
  }
}
