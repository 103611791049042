import { WebConfig } from '@helvault/types'
import { GoogleLoginResponse } from 'react-google-login'

export async function obtainRefreshToken(config: WebConfig, data?: GoogleLoginResponse) {
  const response = await fetch(`${config.apiEndpoint}/auth/refreshToken`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify({ tokenId: data.tokenId, profile: data.profileObj }) : undefined,
    credentials: 'include',
  })

  if (response.ok) {
    return
  }

  throw new Error('Invalid response when requesting refresh token')
}

export const obtainAccessToken = async (config: WebConfig) => {
  const response = await fetch(`${config.apiEndpoint}/auth/accessToken`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })

  if (response.ok) {
    return
  } else if (response.status === 401) {
    return
  }

  throw new Error(`Invalid response when requesting accessToken: ${response.statusText}`)
}

export const signout = async (config: WebConfig) => {
  await fetch(`${config.apiEndpoint}/auth/signout`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
}

export const decodeToken = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

export const tokenExpired = (token: string, timeMarginInSeconds = 3) => {
  if (!token || token.split('.').length < 3) {
    return false
  }
  const { exp } = decodeToken(token)
  const now = Date.now() / 1000
  return now + timeMarginInSeconds > exp
}
