import {
  Checkbox,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  chakra,
} from '@chakra-ui/react'
import { SortingDirection } from '@helvault/types'
import { IconArrowsSort, IconFilter, IconSortDescending } from '@tabler/icons-react'
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'
import { Loading } from '../Loading'
import { Pagination } from '../Pagination'

export { createColumnHelper } from '@tanstack/react-table'

export type ColumnDefMeta = { isNumeric?: boolean } | undefined

export type TableItem = { id: string }

interface BaseSelectedRows {
  all?: boolean
  selected?: string[]
  deselected?: string[]
}

interface SelectedRows extends BaseSelectedRows {
  all: false
  selected: string[]
  deselected?: never
}

interface DeselectedRows extends BaseSelectedRows {
  all: true
  selected?: never
  deselected: string[]
}

export type TableSelectedRows = SelectedRows | DeselectedRows

export type TableFilters<Data extends TableItem> = {
  [key in keyof Data]?: { label: React.ReactNode; value: string }[]
}

export type SelectedFilters<Data extends TableItem> = {
  [key in keyof Data]?: string[]
}

export interface TableFilter<Data extends TableItem> {
  filters: TableFilters<Data>
  activeFilters: SelectedFilters<Data>
  onFilterChange?: (newFilters: SelectedFilters<Data>) => void
}

export interface TableSelection {
  selectedRows: TableSelectedRows
  selectionMenu?: {
    label: React.ReactNode
    onClick: () => void
  }[]
  actions?: React.ReactNode
  onChange: (selection: TableSelectedRows) => void
}

export interface TablePagination {
  totalRows: number
  pageSize: number
  pageSizes?: number[]
  pageIndex: number
  onPageChange: (newPagination: PaginationState) => void
}

export interface TableSortingState {
  columnId: string
  direction: SortingDirection
}

export interface TableSorting extends Partial<TableSortingState> {
  onChange: (newSorting?: TableSortingState) => void
  sortableColumns?: string[]
}

type ColumnDefWithData<Data extends TableItem> = ColumnDef<Data, any>

export type TableProps<Data extends TableItem> = {
  data: Data[]
  rowId?: (row: Data) => string
  columns: ColumnDefWithData<Data>[]
  selection?: TableSelection
  pagination?: TablePagination
  sorting?: TableSorting
  isLoading?: boolean
  loadingText?: string
  filter?: TableFilter<Data>
}

export const defaultRowSelection = (): TableSelectedRows => ({ all: false, selected: [] })

export const Table = <Data extends TableItem>({
  data,
  rowId,
  columns: _columns,
  selection,
  sorting,
  pagination,
  isLoading,
  loadingText,
  filter,
}: TableProps<Data>) => {
  const [sortingState, setSortingState] = useState<SortingState>([])

  const handlePaginationChange: OnChangeFn<PaginationState> = (newPagination) => {
    if (pagination) {
      pagination.onPageChange(typeof newPagination === 'function' ? newPagination(pagination) : newPagination)
    }
    return newPagination
  }

  const handleSortingChange: OnChangeFn<SortingState> = (newSorting) => {
    setSortingState(newSorting)
    if (sorting?.onChange) {
      const newSort = typeof newSorting === 'function' ? newSorting(sortingState) : newSorting

      sorting?.onChange?.(
        newSort.length > 0 ? { columnId: newSort[0].id, direction: newSort[0].desc ? 'desc' : 'asc' } : undefined,
      )
    }
    return newSorting
  }

  const createNewSelection = (id: string, selected?: boolean): TableSelectedRows => {
    if (selection) {
      const selectionState = selection.selectedRows
      if (!selected) {
        if (selectionState.all) {
          const allDeselected = selectionState.deselected.length + 1 === (pagination?.totalRows ?? data.length)

          if (allDeselected) {
            return defaultRowSelection()
          }

          return { all: true, deselected: [...selectionState.deselected, id] }
        } else {
          return { all: false, selected: selectionState.selected.filter((rowId) => rowId !== id) }
        }
      } else {
        if (selectionState.all) {
          return { all: true, deselected: selectionState.deselected.filter((rowId) => rowId !== id) }
        } else {
          const allSelected = selectionState.selected.length + 1 === (pagination?.totalRows ?? data.length)

          if (allSelected) {
            return { all: true, deselected: [] }
          }

          return { all: false, selected: [...selectionState.selected, id] }
        }
      }
    }

    return defaultRowSelection()
  }

  const handleSelectionChange = (id: string, selected?: boolean) => {
    const newSelection = createNewSelection(id, selected)

    updateSelection(newSelection)
  }

  const handleFilterChange = (columnId: keyof Data, value: string | string[]) => {
    const newFilters = {
      ...filter?.activeFilters,
      [columnId]: [value].flat(),
    }

    filter?.onFilterChange?.(newFilters)
  }

  const updateSelection = (newSelection: TableSelectedRows) => {
    if (selection) {
      selection.onChange(newSelection)
    }
  }

  const selectColumn = (rowSelection: TableSelectedRows): ColumnDefWithData<Data> => {
    return {
      id: 'select',
      header: () => {
        const isChecked = rowSelection.all && rowSelection.deselected.length === 0
        const isIntermediate =
          (rowSelection.all && rowSelection.deselected.length > 0) || (rowSelection?.selected || []).length > 0
        return (
          <Checkbox
            {...{
              isIndeterminate: isIntermediate,
              isChecked,
              onChange: () =>
                updateSelection(
                  isChecked
                    ? defaultRowSelection()
                    : {
                        all: true,
                        deselected: [],
                      },
                ),
            }}
          />
        )
      },
      cell: ({ row }) => {
        const isSelected =
          rowSelection?.selected?.includes(row.id) || (rowSelection.all && !rowSelection.deselected.includes(row.id))
        return (
          <Checkbox
            {...{
              isChecked: isSelected,
              onChange: () => handleSelectionChange(row.id, !isSelected),
            }}
          />
        )
      },
      size: 2,
    }
  }

  const columns = selection ? [selectColumn(selection.selectedRows), ..._columns] : _columns

  const table = useReactTable({
    columns,
    data,
    getRowId: (row) => rowId?.(row) || String(row.id),
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: handleSortingChange,
    state: {
      sorting: sortingState,
    },
    manualPagination: true,
    manualSorting: true,
    enableSorting: !!sorting,
  })

  const totalPages = pagination ? Math.ceil(pagination.totalRows / pagination.pageSize) : 1

  return (
    <>
      <Loading.Overlay loading={isLoading} loadingText={loadingText}>
        <ChakraTable>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: ColumnDefMeta = header.column.columnDef.meta
                  const isSortingEnabled =
                    sorting && (!sorting.sortableColumns || sorting.sortableColumns?.includes(header.id))

                  const isSorted = header.column.getIsSorted()

                  return (
                    <Th key={header.id} isNumeric={meta?.isNumeric} width={header.getSize()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}

                      {isSortingEnabled && (
                        <chakra.span
                          pl="4"
                          onClick={isSortingEnabled ? header.column.getToggleSortingHandler() : undefined}
                          cursor="pointer"
                        >
                          {isSorted ? (
                            <IconButton
                              aria-label={`Sorted ${isSorted === 'desc' ? 'descending' : 'ascending'}`}
                              size="sm"
                              variant="ghost"
                              icon={
                                <Icon
                                  as={IconSortDescending}
                                  boxSize={4}
                                  transition="ease-in 0.2s"
                                  transform={isSorted === 'desc' ? 'rotate(180deg)' : undefined}
                                />
                              }
                            />
                          ) : (
                            <IconButton
                              aria-label="Sort"
                              size="sm"
                              variant="ghost"
                              icon={<Icon boxSize={4} as={IconArrowsSort} opacity={0.7} />}
                            />
                          )}
                        </chakra.span>
                      )}

                      {filter?.filters?.[header.id as keyof Data] && (
                        <Menu closeOnSelect={false}>
                          <MenuButton
                            as={IconButton}
                            icon={<Icon as={IconFilter} />}
                            size="sm"
                            variant="ghost"
                            color="inherit"
                          />
                          <Portal>
                            <MenuList maxHeight={200} overflow="scroll">
                              <MenuOptionGroup
                                type="checkbox"
                                onChange={(newValue) => handleFilterChange(header.id as keyof Data, newValue)}
                              >
                                {filter.filters[header.id as keyof Data]?.map((filterItem) => (
                                  <MenuItemOption
                                    key={filterItem.value}
                                    value={filterItem.value}
                                    onClick={() => console.log(filterItem.value)}
                                  >
                                    {filterItem.label}
                                  </MenuItemOption>
                                ))}
                              </MenuOptionGroup>
                            </MenuList>
                          </Portal>
                        </Menu>
                      )}
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: ColumnDefMeta = cell.column.columnDef.meta
                  return (
                    <Td key={cell.id} isNumeric={meta?.isNumeric}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  )
                })}
              </Tr>
            ))}
          </Tbody>
        </ChakraTable>
      </Loading.Overlay>
      {pagination && (
        <Pagination
          page={pagination.pageIndex}
          pageSize={pagination.pageSize}
          totalPages={totalPages}
          onChange={({ page, pageSize }) => handlePaginationChange({ pageIndex: page, pageSize })}
          pageSizes={pagination.pageSizes}
        />
      )}
    </>
  )
}
