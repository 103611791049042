import { Box } from '@chakra-ui/react'
import { shine } from 'lib/keyframes'
import { css, styled } from 'styled-components'
import { cardBorderRadius } from './utils'

export const CardFoiler = styled(Box)<{ $foil?: boolean; $highlightColor?: string }>`
  position: absolute;
  inset: 0;
  z-index: 3;
  overflow: hidden;
  border-radius: ${cardBorderRadius};

  ${(props) =>
    props.$foil &&
    css`
      &:before {
        position: absolute;
        top: -50%;
        left: -50%;
        right: -50%;
        height: 150px;
        border-radius: ${cardBorderRadius};
        background-image: linear-gradient(
          to bottom,
          transparent 0%,
          rgba(255, 255, 255, 0.4) 50%,
          transparent 100%
        );
        transform: rotate(-45deg);
        content: ' ';
        pointer-events: none;
        overflow: hidden;
        opacity: 0.5;
        animation-name: ${shine};
        animation-timing-function: ease-out;
        animation-duration: 7s;
        animation-delay: 750ms;
        animation-iteration-count: infinite;
        z-index: 10;
      }
      &:after {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        display: block;
        border-radius: ${cardBorderRadius};
        background: linear-gradient(-45deg, #e9ee52, #e73c7e, #23a6d5, #23d5ab);
        opacity: 0.15;
        overflow: hidden;
        content: ' ';
        pointer-events: none;
        z-index: 10;
      }
    `}

  ${(props) =>
    props.$highlightColor &&
    css`
      box-shadow: 0px 0px 14px 10px ${props.$highlightColor} inset;
    `}
`
