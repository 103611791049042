import { useMemo } from 'react'
import { CardInDeckListFragment } from 'types/graphql'

export const useCardToShow = (card?: CardInDeckListFragment) => {
  const cardToShow = useMemo(() => {
    return card?.change?.__typename === 'CardInclusionChangeUpdate' && card.change.card ? card.change.card : card?.card
  }, [card])

  const inclusionData = useMemo(() => {
    return card?.change?.__typename === 'CardInclusionChangeUpdate' ? card.change : card
  }, [card])

  return {
    card: cardToShow,
    data: inclusionData,
  }
}
